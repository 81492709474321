import { useEffect, useState } from "react";
import RadionButtons from '../RadioButtons/RadioButtons';
import Scores from '../Scores/Scores';
import "./Planning.css";

export default function Planning({
    setShowNotification, setNotificationText,
    inputValidity, setInputValidity, inputCheck,
    setSectionScore,
    setTaskScore1, setTaskScore2, setTaskScore3, setTaskScore4,
    setTaskScore5, setTaskScore6, setTaskScore7,
    subTask1, setSubTask1, subTask2, setSubTask2, subTask3, setSubTask3, subTask4, setSubTask4, 
    subTask5, setSubTask5, subTask6, setSubTask6, subTask7, setSubTask7, subTask8, setSubTask8, 
    subTask9, setSubTask9, subTask10, setSubTask10, subTask11, setSubTask11, subTask12, setSubTask12, 
    subTask13, setSubTask13, subTask14, setSubTask14, subTask15, setSubTask15, subTask16, setSubTask16, 
    subTask17, setSubTask17, subTask18, setSubTask18, subTask19, setSubTask19, subTask20, setSubTask20, 
    subTask21, setSubTask21, subTask22, setSubTask22, subTask23, setSubTask23, subTask24, setSubTask24, 
    subTask25, setSubTask25, subTask26, setSubTask26, subTask27, setSubTask27, subTask28, setSubTask28
   }) {

    const [subTaksValidity1, setSubTaskValidity1] = useState(true)
    const [subTaksValidity2, setSubTaskValidity2] = useState(true)
    const [subTaksValidity3, setSubTaskValidity3] = useState(true)
    const [subTaksValidity4, setSubTaskValidity4] = useState(true)
    const [subTaksValidity5, setSubTaskValidity5] = useState(true)
    const [subTaksValidity6, setSubTaskValidity6] = useState(true)
    const [subTaksValidity7, setSubTaskValidity7] = useState(true)
    const [subTaksValidity8, setSubTaskValidity8] = useState(true)
    const [subTaksValidity9, setSubTaskValidity9] = useState(true)
    const [subTaksValidity10, setSubTaskValidity10] = useState(true)
    const [subTaksValidity11, setSubTaskValidity11] = useState(true)
    const [subTaksValidity12, setSubTaskValidity12] = useState(true)
    const [subTaksValidity13, setSubTaskValidity13] = useState(true)
    const [subTaksValidity14, setSubTaskValidity14] = useState(true)
    const [subTaksValidity15, setSubTaskValidity15] = useState(true)
    const [subTaksValidity16, setSubTaskValidity16] = useState(true)
    const [subTaksValidity17, setSubTaskValidity17] = useState(true)
    const [subTaksValidity18, setSubTaskValidity18] = useState(true)
    const [subTaksValidity19, setSubTaskValidity19] = useState(true)
    const [subTaksValidity20, setSubTaskValidity20] = useState(true)
    const [subTaksValidity21, setSubTaskValidity21] = useState(true)
    const [subTaksValidity22, setSubTaskValidity22] = useState(true)
    const [subTaksValidity23, setSubTaskValidity23] = useState(true)
    const [subTaksValidity24, setSubTaskValidity24] = useState(true)
    const [subTaksValidity25, setSubTaskValidity25] = useState(true)
    const [subTaksValidity26, setSubTaskValidity26] = useState(true)
    const [subTaksValidity27, setSubTaskValidity27] = useState(true)
    const [subTaksValidity28, setSubTaskValidity28] = useState(true)

    const checkInputValidity = () => {
        
        let notificationText = 'Please fill in subtask(s) '
        let validity = true

        if(subTask1 === null) {
            setSubTaskValidity1(false)
            notificationText += '7.1, '
            validity = false
        }
        if(subTask2  === null) {
            setSubTaskValidity2(false)
            notificationText += '7.2, '
            validity = false
        }
        if(subTask3 === null) {
            setSubTaskValidity3(false)
            notificationText += '7.3, '
            validity = false
        }
        if(subTask4 === null) {
            setSubTaskValidity4(false)
            notificationText += '8.1, '
            validity = false
        }
        if(subTask5 === null) {
            setSubTaskValidity5(false)
            notificationText += '8.2, '
            validity = false
        }
        if(subTask6 === null) {
            setSubTaskValidity6(false)
            notificationText += '8.3, '
            validity = false
        }
        if(subTask7 === null) {
            setSubTaskValidity7(false)
            notificationText += '8.4, '
            validity = false
        }
        if(subTask8 === null) {
            setSubTaskValidity8(false)
            notificationText += '9.1, '
            validity = false
        }
        if(subTask9  === null) {
            setSubTaskValidity9(false)
            notificationText += '9.2, '
            validity = false
        }
        if(subTask10 === null) {
            setSubTaskValidity10(false)
            notificationText += '9.3, '
            validity = false
        }
        if(subTask11 === null) {
            setSubTaskValidity11(false)
            notificationText += '9.4, '
            validity = false
        }
        if(subTask12 === null) {
            setSubTaskValidity12(false)
            notificationText += '9.5, '
            validity = false
        }
        if(subTask13 === null) {
            setSubTaskValidity13(false)
            notificationText += '10.1, '
            validity = false
        }
        if(subTask14 === null) {
            setSubTaskValidity14(false)
            notificationText += '10.2, '
            validity = false
        }
        if(subTask15 === null) {
            setSubTaskValidity15(false)
            notificationText += '10.3, '
            validity = false
        }
        if(subTask16  === null) {
            setSubTaskValidity16(false)
            notificationText += '11.1, '
            validity = false
        }
        if(subTask17 === null) {
            setSubTaskValidity17(false)
            notificationText += '11.2, '
            validity = false
        }
        if(subTask18 === null) {
            setSubTaskValidity18(false)
            notificationText += '11.3, '
            validity = false
        }
        if(subTask19 === null) {
            setSubTaskValidity19(false)
            notificationText += '11.4, '
            validity = false
        }
        if(subTask20 === null) {
            setSubTaskValidity20(false)
            notificationText += '11.5, '
            validity = false
        }
        if(subTask21 === null) {
            setSubTaskValidity21(false)
            notificationText += '11.6, '
            validity = false
        }
        if(subTask22 === null) {
            setSubTaskValidity22(false)
            notificationText += '12.1, '
            validity = false
        }
        if(subTask23  === null) {
            setSubTaskValidity23(false)
            notificationText += '12.2, '
            validity = false
        }
        if(subTask24 === null) {
            setSubTaskValidity24(false)
            notificationText += '12.3, '
            validity = false
        }
        if(subTask25 === null) {
            setSubTaskValidity25(false)
            notificationText += '13.1, '
            validity = false
        }
        if(subTask26 === null) {
            setSubTaskValidity26(false)
            notificationText += '13.2, '
            validity = false
        }
        if(subTask27 === null) {
            setSubTaskValidity27(false)
            notificationText += '13.3, '
            validity = false
        }
        if(subTask28 === null) {
            setSubTaskValidity28(false)
            notificationText += '13.4, '
            validity = false
        }

        setInputValidity(inputValidity => inputValidity.map((inputValidityNew, index) =>
            index === 3 ? validity : inputValidityNew
        ));

        if(!validity) {
            setNotificationText(notificationText.substring(0, notificationText.length - 2))
            setShowNotification(true)
        } else {
            calculateSectionScore()
            calculateTasksScore()
        }

    }

    const calculateSectionScore = () => {
        setSectionScore(Math.round((subTask1 + subTask2 + subTask3 + subTask4 + subTask5 + subTask6 + subTask7 + subTask8 + subTask9 + subTask10 + subTask11 + subTask12 + subTask13 + subTask14 + subTask15 + subTask16 + subTask17 + subTask18 + subTask19 + subTask20 + subTask21 + subTask22 + subTask23 + subTask24 + subTask25 + subTask26 + subTask27 + subTask28) / 28 * 20))
    }

    const calculateTasksScore = () => {
        setTaskScore1(Math.round((subTask1 + subTask2 + subTask3) / 3 * 20))
        setTaskScore2(Math.round((subTask4 + subTask5 + subTask6 + subTask7) / 4 * 20))
        setTaskScore3(Math.round((subTask8 + subTask9 + subTask10 + subTask11 + subTask12) / 5 * 20))
        setTaskScore4(Math.round((subTask13 + subTask14 + subTask15) / 3 * 20))
        setTaskScore5(Math.round((subTask16 + subTask17 + subTask18 + subTask19 + subTask20 + subTask21) / 6 * 20))
        setTaskScore6(Math.round((subTask22 + subTask23 + subTask24) / 3 * 20))
        setTaskScore7(Math.round((subTask25 + subTask26 + subTask27 + subTask28) / 4 * 20))
    }

    useEffect(() => {
        if(inputCheck[3]) checkInputValidity()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [inputCheck])

    return (
        <div className="step-wrapper" >
            <div className='heading-wrapper'>
                <h2 className="step-heading">Planning</h2>
                <Scores></Scores>
            </div>
            <div className="inputs-wrapper">
                <div className='task'>
                    <h2 className='task-heading'>Task 7: Risks to EnMS Success</h2>
                    <div className='sub-tasks-wrapper'>
                        <div className='sub-task'>
                            <div className='sub-task-label'><span className="sub-task-index">Subtask 7.1:</span>Do you identify the risks and opportunities pertaining to the ability of your energy management system (EnMS) to achieve its intended outcomes?</div>
                            <RadionButtons value={subTask1} setValue={setSubTask1} valid={subTaksValidity1} setValid={setSubTaskValidity1}></RadionButtons>
                        </div>
                        <div className='sub-task'>
                            <div className='sub-task-label'><span className="sub-task-index">Subtask 7.2:</span>For each of the identified risks and opportunities, do you plan and implement actions to address them using the processes of the EnMS, and record this information in order to  prevent or decrease adverse effects and to continuously improve the EnMS and energy performance?</div>
                            <RadionButtons value={subTask2} setValue={setSubTask2} valid={subTaksValidity2} setValid={setSubTaskValidity2}></RadionButtons>
                        </div>
                        <div className='sub-task'>
                            <div className='sub-task-label'><span className="sub-task-index">Subtask 7.3:</span>Do you have a plan for integrating the actions taken into the organization's processes and evaluating their effectiveness?</div>
                            <RadionButtons value={subTask3} setValue={setSubTask3} valid={subTaksValidity3} setValid={setSubTaskValidity3}></RadionButtons>
                        </div>
                    </div>
                </div>
            </div>
            <div className="inputs-wrapper">
                <div className='task'>
                    <h2 className='task-heading'>Task 8: Energy Data Collection Plan</h2>
                    <div className='sub-tasks-wrapper'>
                        <div className='sub-task'>
                            <div className='sub-task-label'><span className="sub-task-index">Subtask 8.1:</span>Do you identify relevant variables that may influence the energy consumption of significant energy uses (SEUs) and that would aid in the development of meaningful energy performance indicators (EnPIs) and energy baselines (EnBs)?</div>
                            <RadionButtons value={subTask4} setValue={setSubTask4} valid={subTaksValidity4} setValid={setSubTaskValidity4}></RadionButtons>
                        </div>
                        <div className='sub-task'>
                            <div className='sub-task-label'><span className="sub-task-index">Subtask 8.2:</span>Do you develop and implement a data collection plan based upon the data needs and the complexity of the organization?</div>
                            <RadionButtons value={subTask5} setValue={setSubTask5} valid={subTaksValidity5} setValid={setSubTaskValidity5}></RadionButtons>
                        </div>
                        <div className='sub-task'>
                            <div className='sub-task-label'><span className="sub-task-index">Subtask 8.3:</span>Do you ensure that measurements and metering are conducted accurately and are repeatable?</div>
                            <RadionButtons value={subTask6} setValue={setSubTask6} valid={subTaksValidity6} setValid={setSubTaskValidity6}></RadionButtons>
                        </div>
                        <div className='sub-task'>
                            <div className='sub-task-label'><span className="sub-task-index">Subtask 8.4:</span>Do you determine appropriate analysis techniques and apply them to understand and track energy consumption and energy use?</div>
                            <RadionButtons value={subTask7} setValue={setSubTask7} valid={subTaksValidity7} setValid={setSubTaskValidity7}></RadionButtons>
                        </div>
                    </div>
                </div>
            </div>
            <div className="inputs-wrapper">
                <div className='task'>
                    <h2 className='task-heading'>Task 9: Significant Energy Uses (SEUs)</h2>
                    <div className='sub-tasks-wrapper'>
                    <div className='sub-task'>
                            <div className='sub-task-label'><span className="sub-task-index">Subtask 9.1:</span>Do you identify the energy uses that consume the most energy within your boundaries?</div>
                            <RadionButtons value={subTask8} setValue={setSubTask8} valid={subTaksValidity8} setValid={setSubTaskValidity8}></RadionButtons>
                        </div>
                        <div className='sub-task'>
                            <div className='sub-task-label'><span className="sub-task-index">Subtask 9.2:</span>Do you identify factors and persons that affect the energy consumption of identified energy uses?</div>
                            <RadionButtons value={subTask9} setValue={setSubTask9} valid={subTaksValidity9} setValid={setSubTaskValidity9}></RadionButtons>
                        </div>
                        <div className='sub-task'>
                            <div className='sub-task-label'><span className="sub-task-index">Subtask 9.3:</span>Do you establish a selection criteria for identifying which of these energy uses should be a significant energy use (SEU)?</div>
                            <RadionButtons value={subTask10} setValue={setSubTask10} valid={subTaksValidity10} setValid={setSubTaskValidity10}></RadionButtons>
                        </div>
                        <div className='sub-task'>
                            <div className='sub-task-label'><span className="sub-task-index">Subtask 9.4:</span>Do you determine SEU energy performance based upon energy consumption and relevant variables as appropriate?</div>
                            <RadionButtons value={subTask11} setValue={setSubTask11} valid={subTaksValidity11} setValid={setSubTaskValidity11}></RadionButtons>
                        </div>
                        <div className='sub-task'>
                            <div className='sub-task-label'><span className="sub-task-index">Subtask 9.5:</span>Do you review the SEU selection criteria as part of the SEU update process?</div>
                            <RadionButtons value={subTask12} setValue={setSubTask12} valid={subTaksValidity12} setValid={setSubTaskValidity12}></RadionButtons>
                        </div>
                    </div>
                </div>
            </div>
            <div className="inputs-wrapper">
                <div className='task'>
                    <h2 className='task-heading'>Task 10: Improvement Opportunities</h2>
                    <div className='sub-tasks-wrapper'>
                        <div className='sub-task'>
                            <div className='sub-task-label'><span className="sub-task-index">Subtask 10.1:</span>Do you develop and document a methodology and criteria for how your organization will identify, prioritize, and update energy performance improvement opportunities?</div>
                            <RadionButtons value={subTask13} setValue={setSubTask13} valid={subTaksValidity13} setValid={setSubTaskValidity13}></RadionButtons>
                        </div>
                        <div className='sub-task'>
                            <div className='sub-task-label'><span className="sub-task-index">Subtask 10.2:</span>Do you apply the methodology and criteria you developed to identify, prioritize, and update energy performance improvement opportunities?</div>
                            <RadionButtons value={subTask14} setValue={setSubTask14} valid={subTaksValidity14} setValid={setSubTaskValidity14}></RadionButtons>
                        </div>
                        <div className='sub-task'>
                            <div className='sub-task-label'><span className="sub-task-index">Subtask 10.3:</span>Do you update the list of prioritized improvement opportunities at specific intervals and when major changes in sites, equipment, systems, or energy-using processes take place?</div>
                            <RadionButtons value={subTask15} setValue={setSubTask15} valid={subTaksValidity15} setValid={setSubTaskValidity15}></RadionButtons>
                        </div>
                    </div>
                </div>
            </div>
            <div className="inputs-wrapper">
                <div className='task'>
                    <h2 className='task-heading'>Task 11: Energy Performance Indicators and Energy Baselines</h2>
                    <div className='sub-tasks-wrapper'>
                    <div className='sub-task'>
                            <div className='sub-task-label'><span className="sub-task-index">Subtask 11.1:</span>Do you develop one or more energy performance indicators (EnPIs) for your organization?</div>
                            <RadionButtons value={subTask16} setValue={setSubTask16} valid={subTaksValidity16} setValid={setSubTaskValidity16}></RadionButtons>
                        </div>
                        <div className='sub-task'>
                            <div className='sub-task-label'><span className="sub-task-index">Subtask 11.2:</span>Do you develop an energy baseline (EnB) for EnPI in order to later determine energy performance improvement?</div>
                            <RadionButtons value={subTask17} setValue={setSubTask17} valid={subTaksValidity17} setValid={setSubTaskValidity17}></RadionButtons>
                        </div>
                        <div className='sub-task'>
                            <div className='sub-task-label'><span className="sub-task-index">Subtask 11.3:</span>Do you communicate proposed EnPIs and EnBs to top management so they can ensure the EnPIs and EnBs are appropriate for the organization?</div>
                            <RadionButtons value={subTask18} setValue={setSubTask18} valid={subTaksValidity18} setValid={setSubTaskValidity18}></RadionButtons>
                        </div>
                        <div className='sub-task'>
                            <div className='sub-task-label'><span className="sub-task-index">Subtask 11.4:</span>Do you record and regularly review the method used to determine and update EnPIs, and establish the conditions under which adjustments to the baseline(s) will be made?</div>
                            <RadionButtons value={subTask19} setValue={setSubTask19} valid={subTaksValidity19} setValid={setSubTaskValidity19}></RadionButtons>
                        </div>
                        <div className='sub-task'>
                            <div className='sub-task-label'><span className="sub-task-index">Subtask 11.5:</span>Do you compare EnPI values to their respective EnBs on a regular basis?</div>
                            <RadionButtons value={subTask20} setValue={setSubTask20} valid={subTaksValidity20} setValid={setSubTaskValidity20}></RadionButtons>
                        </div>
                        <div className='sub-task'>
                            <div className='sub-task-label'><span className="sub-task-index">Subtask 11.6:</span>Do you implement a process for ongoing monitoring, measurement, and analysis of your EnPIs, EnBs, and energy performance improvements?</div>
                            <RadionButtons value={subTask21} setValue={setSubTask21} valid={subTaksValidity21} setValid={setSubTaskValidity21}></RadionButtons>
                        </div>
                    </div>
                </div>
            </div>
            <div className="inputs-wrapper">
                <div className='task'>
                    <h2 className='task-heading'>Task 12: Objectives and Targets</h2>
                    <div className='sub-tasks-wrapper'>
                        <div className='sub-task'>
                            <div className='sub-task-label'><span className="sub-task-index">Subtask 12.1:</span>Do you develop and record your organization’s objectives and energy targets?</div>
                            <RadionButtons value={subTask22} setValue={setSubTask22} valid={subTaksValidity22} setValid={setSubTaskValidity22}></RadionButtons>
                        </div>
                        <div className='sub-task'>
                            <div className='sub-task-label'><span className="sub-task-index">Subtask 12.2:</span>Do you obtain top management’s approval of the objectives and energy targets and communicate appropriately?</div>
                            <RadionButtons value={subTask23} setValue={setSubTask23} valid={subTaksValidity23} setValid={setSubTaskValidity23}></RadionButtons>
                        </div>
                        <div className='sub-task'>
                            <div className='sub-task-label'><span className="sub-task-index">Subtask 12.3:</span>Do you communicate the energy objectives and energy targets appropriately to your organization?</div>
                            <RadionButtons value={subTask24} setValue={setSubTask24} valid={subTaksValidity24} setValid={setSubTaskValidity24}></RadionButtons>
                        </div>
                    </div>
                </div>
            </div>
            <div className="inputs-wrapper">
                <div className='task'>
                    <h2 className='task-heading'>Task 13: Action Plans for Continual Improvement</h2>
                    <div className='sub-tasks-wrapper'>
                        <div className='sub-task'>
                            <div className='sub-task-label'><span className="sub-task-index">Subtask 13.1:</span>Do you use your prioritized opportunities from Task 10: Improvement Opportunities to select projects for implementation?</div>
                            <RadionButtons value={subTask25} setValue={setSubTask25} valid={subTaksValidity25} setValid={setSubTaskValidity25}></RadionButtons>
                        </div>
                        <div className='sub-task'>
                            <div className='sub-task-label'><span className="sub-task-index">Subtask 13.2:</span>Do you apply any applicable criteria set by your organization to justify and gain approval of the project?</div>
                            <RadionButtons value={subTask26} setValue={setSubTask26} valid={subTaksValidity26} setValid={setSubTaskValidity26}></RadionButtons>
                        </div>
                        <div className='sub-task'>
                            <div className='sub-task-label'><span className="sub-task-index">Subtask 13.3:</span>Do you develop action plans for meeting your organization’s objectives and energy targets identified in Task 12: Objectives and Targets?</div>
                            <RadionButtons value={subTask27} setValue={setSubTask27} valid={subTaksValidity27} setValid={setSubTaskValidity27}></RadionButtons>
                        </div>
                        <div className='sub-task'>
                            <div className='sub-task-label'><span className="sub-task-index">Subtask 13.4:</span>Do you communicate expectations to relevant positions and review action plan progress?</div>
                            <RadionButtons value={subTask28} setValue={setSubTask28} valid={subTaksValidity28} setValid={setSubTaskValidity28}></RadionButtons>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
  }