import logo from "../../Assets/Images/logo.png"
import "./Header.css"

export default function Header() {
    return (
        <div className="header">
            <img className="header-logo" src={logo} alt="logo"/>
            <h1 className="header-h1">A2M Energy Management Maturity Model</h1>
        </div>
    );
  }