import { useEffect, useState } from "react";
import "./OrganisationInfo.css"
import RadionButtons from '../RadioButtons/RadioButtons';
import Scores from '../Scores/Scores';

export default function OrganisationInfo({
    setShowNotification, setNotificationText,
    inputValidity, setInputValidity, inputCheck,
    setSectionScore, 
    setTaskScore1, setTaskScore2, setTaskScore3,
    subTask1, setSubTask1, subTask2, setSubTask2, subTask3, setSubTask3, subTask4, setSubTask4, 
    subTask5, setSubTask5, subTask6, setSubTask6, subTask7, setSubTask7
   }) {

    const [subTaksValidity1, setSubTaskValidity1] = useState(true)
    const [subTaksValidity2, setSubTaskValidity2] = useState(true)
    const [subTaksValidity3, setSubTaskValidity3] = useState(true)
    const [subTaksValidity4, setSubTaskValidity4] = useState(true)
    const [subTaksValidity5, setSubTaskValidity5] = useState(true)
    const [subTaksValidity6, setSubTaskValidity6] = useState(true)
    const [subTaksValidity7, setSubTaskValidity7] = useState(true)

    const checkInputValidity = () => {
        
        let notificationText = 'Please fill in subtask(s) '
        let validity = true

        if(subTask1 === null) {
            setSubTaskValidity1(false)
            notificationText += '1.1, '
            validity = false
        }
        if(subTask2  === null) {
            setSubTaskValidity2(false)
            notificationText += '1.2, '
            validity = false
        }
        if(subTask3 === null) {
            setSubTaskValidity3(false)
            notificationText += '2.1, '
            validity = false
        }
        if(subTask4 === null) {
            setSubTaskValidity4(false)
            notificationText += '2.2, '
            validity = false
        }
        if(subTask5 === null) {
            setSubTaskValidity5(false)
            notificationText += '2.3, '
            validity = false
        }
        if(subTask6 === null) {
            setSubTaskValidity6(false)
            notificationText += '3.1, '
            validity = false
        }
        if(subTask7 === null) {
            setSubTaskValidity7(false)
            notificationText += '3.2, '
            validity = false
        }

        setInputValidity(inputValidity => inputValidity.map((inputValidityNew, index) =>
            index === 1 ? validity : inputValidityNew
        ));

        if(!validity) {
            setNotificationText(notificationText.substring(0, notificationText.length - 2))
            setShowNotification(true)
        } else {
            calculateSectionScore()
            calculateTasksScore()
        }

    }

    const calculateSectionScore = () => {
        setSectionScore(Math.round((subTask1 + subTask2 + subTask3 + subTask4 + subTask5 + subTask6 + subTask7) / 7 * 20))
    }

    const calculateTasksScore = () => {
        setTaskScore1(Math.round((subTask1 + subTask2) / 2 * 20))
        setTaskScore2(Math.round((subTask3 + subTask4 + subTask5) / 3 * 20))
        setTaskScore3(Math.round((subTask6 + subTask7) / 2 * 20))
    }
 
    useEffect(() => {
        if(inputCheck[1]) checkInputValidity()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [inputCheck])

    return (
        <div className="step-wrapper">
            <div className='heading-wrapper'>
                <h2 className="step-heading">Organizational Context</h2>
                <Scores></Scores>
            </div>
            <div className="inputs-wrapper">
                <div className='task'>
                    <h2 className='task-heading'>Task 1: An EnMS and Your Company</h2>
                    <div className='sub-tasks-wrapper'>
                        <div className='sub-task'>
                            <div className='sub-task-label'><span className="sub-task-index">Subtask 1.1:</span>Do you identify the external and internal strategic issues that influence your organization's ability to improve its energy performance and achieve the intended energy management system (EnMS) outcomes?</div>
                            <RadionButtons value={subTask1} setValue={setSubTask1} valid={subTaksValidity1} setValid={setSubTaskValidity1}></RadionButtons>
                        </div>
                        <div className='sub-task'>
                            <div className='sub-task-label'><span className="sub-task-index">Subtask 1.2:</span>Do you maintain a record for this information?</div>
                            <RadionButtons value={subTask2} setValue={setSubTask2} valid={subTaksValidity2} setValid={setSubTaskValidity2}></RadionButtons>
                        </div>
                    </div>
                </div>
            </div>
            <div className="inputs-wrapper">
                <div className='task'>
                    <h2 className='task-heading'>Task 2: Needs and Expectations of Interested Parties</h2>
                    <div className='sub-tasks-wrapper'>
                        <div className='sub-task'>
                            <div className='sub-task-label'><span className="sub-task-index">Subtask 2.1:</span>Do you identify and record the interested parties relevant to your company's energy performance and energy management activities (EnMS)?</div>
                            <RadionButtons value={subTask3} setValue={setSubTask3} valid={subTaksValidity3} setValid={setSubTaskValidity3}></RadionButtons>
                        </div>
                        <div className='sub-task'>
                            <div className='sub-task-label'><span className="sub-task-index">Subtask 2.2:</span>Do you determine the needs and expectations of these interested parties as they relate to your organization’s EnMS?</div>
                            <RadionButtons value={subTask4} setValue={setSubTask4} valid={subTaksValidity4} setValid={setSubTaskValidity4}></RadionButtons>
                        </div>
                        <div className='sub-task'>
                            <div className='sub-task-label'><span className="sub-task-index">Subtask 2.3:</span>Do you implement a process to periodically evaluate compliance with the identified needs and expectations?</div>
                            <RadionButtons value={subTask5} setValue={setSubTask5} valid={subTaksValidity5} setValid={setSubTaskValidity5}></RadionButtons>
                        </div>
                    </div>
                </div>
            </div>
            <div className="inputs-wrapper">
                <div className='task'>
                    <h2 className='task-heading'>Task 3: Scope and Boundaries of the EnMS</h2>
                    <div className='sub-tasks-wrapper'>
                        <div className='sub-task'>
                            <div className='sub-task-label'><span className="sub-task-index">Subtask 3.1:</span>Do you determine and document the scope of the energy management system?</div>
                            <RadionButtons value={subTask6} setValue={setSubTask6} valid={subTaksValidity6} setValid={setSubTaskValidity6}></RadionButtons>
                        </div>
                        <div className='sub-task'>
                            <div className='sub-task-label'><span className="sub-task-index">Subtask 3.2:</span>Do you take into account the strategic issues and requirements indicated in Task 1: An EnMS and Your Company when determining the scope and boundaries of the energy management system (EnMS)?</div>
                            <RadionButtons value={subTask7} setValue={setSubTask7} valid={subTaksValidity7} setValid={setSubTaskValidity7}></RadionButtons>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
  }