import gsap from 'gsap';
import { useGSAP } from '@gsap/react';
import { useEffect, useRef, useState } from "react";
import "./TaskCharacterization.css"

export default function TaskCharacterization({score}) {

    const [isOpen, setIsOpen] = useState(false)
    const [text, setText] = useState()
    const [tooltipContent, setTooltipContent] = useState()

    const characterizationContainer = useRef()
    const value = useRef()
    const title = useRef()

    gsap.registerPlugin(useGSAP);

    const {contextSafe} = useGSAP({scope: characterizationContainer}); 

    const colorScale = ['#fe9f9f', '#e39ec9', '#bfa4d7', '#9ea8cc', '#89a8b5']

    const showTooltip = () => {
        setIsOpen(true)
    }

    const hideToolTip = () => {
        setIsOpen(false)
    }

    const taskCharacteriazations = ['informal', 'documented', 'integrated', 'strategic', 'optimized']
    const taskCharacterizationsTexts = [
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
    ]

    const setTaskCharacterization  = contextSafe(() => {
        if(score < 20) {
            gsap.set(value.current, {color: colorScale[0]})
            setText(taskCharacteriazations[0])
        } else if(score < 40) {
            gsap.set(value.current, {color: colorScale[1]})
            setText(taskCharacteriazations[1])
        } else if(score < 60) {
            gsap.set(value.current, {color: colorScale[2]})
            setText(taskCharacteriazations[2])
        } else if(score < 80) {
            gsap.set(value.current, {color: colorScale[3]})
            setText(taskCharacteriazations[3])
        } else {
            gsap.set(value.current, {color: colorScale[4]})
            setText(taskCharacteriazations[4])
        }
    })

    const setTaskChacterizationTooltip = () => {
        if(score < 20) {
            setTooltipContent(taskCharacterizationsTexts[0])
        } else if(score < 40) {
            setTooltipContent(taskCharacterizationsTexts[1])
        } else if(score < 60) {
            setTooltipContent(taskCharacterizationsTexts[2])
        } else if(score < 80) {
            setTooltipContent(taskCharacterizationsTexts[3])
        } else {
            setTooltipContent(taskCharacterizationsTexts[4])
        }
    }

    useEffect(() => {
       setTaskCharacterization()
       setTaskChacterizationTooltip()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    useEffect(() => {
        if(isOpen) {
            if(score < 20) {
                gsap.set(title.current, {backgroundColor: colorScale[0]})
            } else if(score < 40) {
                gsap.set(title.current, {backgroundColor: colorScale[1]})
            } else if(score < 60) {
                gsap.set(title.current, {backgroundColor: colorScale[2]})
            } else if(score < 80) {
                gsap.set(title.current, {backgroundColor: colorScale[3]})
            } else {
                gsap.set(title.current, {backgroundColor: colorScale[4]})
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpen])
    

    return (
        <div className="task-characterization" ref={characterizationContainer}>
            <p className="task-characterization-value" ref={value} onMouseEnter={showTooltip} onMouseLeave={hideToolTip}>{text}</p>
            {isOpen &&
                <p className="task-characterization-title" ref={title}>{tooltipContent}</p>
            }
        </div>
    );
  }