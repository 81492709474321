import { useEffect, useState } from "react";
import RadionButtons from '../RadioButtons/RadioButtons';
import Scores from '../Scores/Scores';
import "./Operations.css";

export default function Operations({
    setShowNotification, setNotificationText,
    inputValidity, setInputValidity, inputCheck,
    setSectionScore,
    setTaskScore1, setTaskScore2, setTaskScore3,
    subTask1, setSubTask1, subTask2, setSubTask2, subTask3, setSubTask3, subTask4, setSubTask4, 
    subTask5, setSubTask5, subTask6, setSubTask6, subTask7, setSubTask7, subTask8, setSubTask8, 
    subTask9, setSubTask9,
   }) {

    const [subTaksValidity1, setSubTaskValidity1] = useState(true)
    const [subTaksValidity2, setSubTaskValidity2] = useState(true)
    const [subTaksValidity3, setSubTaskValidity3] = useState(true)
    const [subTaksValidity4, setSubTaskValidity4] = useState(true)
    const [subTaksValidity5, setSubTaskValidity5] = useState(true)
    const [subTaksValidity6, setSubTaskValidity6] = useState(true)
    const [subTaksValidity7, setSubTaskValidity7] = useState(true)
    const [subTaksValidity8, setSubTaskValidity8] = useState(true)
    const [subTaksValidity9, setSubTaskValidity9] = useState(true)

    const checkInputValidity = () => {
        
        let notificationText = 'Please fill in subtask(s) '
        let validity = true

        if(subTask1 === null) {
            setSubTaskValidity1(false)
            notificationText += '17.1, '
            validity = false
        }
        if(subTask2  === null) {
            setSubTaskValidity2(false)
            notificationText += '17.2, '
            validity = false
        }
        if(subTask3 === null) {
            setSubTaskValidity3(false)
            notificationText += '17.3, '
            validity = false
        }
        if(subTask4 === null) {
            setSubTaskValidity4(false)
            notificationText += '18.1, '
            validity = false
        }
        if(subTask5 === null) {
            setSubTaskValidity5(false)
            notificationText += '18.2, '
            validity = false
        }
        if(subTask6 === null) {
            setSubTaskValidity6(false)
            notificationText += '18.3, '
            validity = false
        }
        if(subTask7 === null) {
            setSubTaskValidity7(false)
            notificationText += '19.1, '
            validity = false
        }
        if(subTask8 === null) {
            setSubTaskValidity8(false)
            notificationText += '19.2, '
            validity = false
        }
        if(subTask9  === null) {
            setSubTaskValidity9(false)
            notificationText += '19.3, '
            validity = false
        }

        setInputValidity(inputValidity => inputValidity.map((inputValidityNew, index) =>
            index === 5 ? validity : inputValidityNew
        ));

        if(!validity) {
            setNotificationText(notificationText.substring(0, notificationText.length - 2))
            setShowNotification(true)
        } else {
            calculateSectionScore()
            calculateTasksScore()
        }

    }

    const calculateSectionScore = () => {
        setSectionScore(Math.round((subTask1 + subTask2 + subTask3 + subTask4 + subTask5 + subTask6 + subTask7 + subTask8 + subTask9) / 9 * 20))
    }

    const calculateTasksScore = () => {
        setTaskScore1(Math.round((subTask1 + subTask2 + subTask3) / 3 * 20))
        setTaskScore2(Math.round((subTask4 + subTask5 + subTask6) / 3 * 20))
        setTaskScore3(Math.round((subTask7 + subTask8 + subTask9) / 3 * 20))
    }

    useEffect(() => {
        if(inputCheck[5]) checkInputValidity()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [inputCheck])

    return (
        <div className="step-wrapper">
            <div className='heading-wrapper'>
                <h2 className="step-heading">Operations</h2>
                <Scores></Scores>
            </div>
            <div className="inputs-wrapper">
                <div className='task'>
                    <h2 className='task-heading'>Task 17: Operational planning and control</h2>
                    <div className='sub-tasks-wrapper'>
                        <div className='sub-task'>
                            <div className='sub-task-label'><span className="sub-task-index">Subtask 17.1:</span>Do you develop a significant energy use (SEU) operating criteria worksheet and a checklist of operational controls to determine and establish the appropriate criteria and controls for each significant energy use?</div>
                            <RadionButtons value={subTask1} setValue={setSubTask1} valid={subTaksValidity1} setValid={setSubTaskValidity1}></RadionButtons>
                        </div>
                        <div className='sub-task'>
                            <div className='sub-task-label'><span className="sub-task-index">Subtask 17.2:</span>Do you ensure that the aforementioned criteria/controls affecting energy performance are known , communicated to all relevant persons and documented?</div>
                            <RadionButtons value={subTask2} setValue={setSubTask2} valid={subTaksValidity2} setValid={setSubTaskValidity2}></RadionButtons>
                        </div>
                        <div className='sub-task'>
                            <div className='sub-task-label'><span className="sub-task-index">Subtask 17.3:</span>Do you ensure the control of processess in accordance with the established criteria?</div>
                            <RadionButtons value={subTask3} setValue={setSubTask3} valid={subTaksValidity3} setValid={setSubTaskValidity3}></RadionButtons>
                        </div>
                    </div>
                </div>
            </div>
            <div className="inputs-wrapper">
                <div className='task'>
                    <h2 className='task-heading'>Task 18: Energy Considerations in Design</h2>
                    <div className='sub-tasks-wrapper'>
                        <div className='sub-task'>
                            <div className='sub-task-label'><span className="sub-task-index">Subtask 18.1:</span>Do you identify the sites, equipment, systems, and processes that can have significant impact on energy performance?</div>
                            <RadionButtons value={subTask4} setValue={setSubTask4} valid={subTaksValidity4} setValid={setSubTaskValidity4}></RadionButtons>
                        </div>
                        <div className='sub-task'>
                            <div className='sub-task-label'><span className="sub-task-index">Subtask 18.2:</span>Do you incorporate consideration of energy opportunities and operational controls in design projects?</div>
                            <RadionButtons value={subTask5} setValue={setSubTask5} valid={subTaksValidity5} setValid={setSubTaskValidity5}></RadionButtons>
                        </div>
                        <div className='sub-task'>
                            <div className='sub-task-label'><span className="sub-task-index">Subtask 18.3:</span>Do you document the results of design activities related to energy performance?</div>
                            <RadionButtons value={subTask6} setValue={setSubTask6} valid={subTaksValidity6} setValid={setSubTaskValidity6}></RadionButtons>
                        </div>
                    </div>
                </div>
            </div>
            <div className="inputs-wrapper">
                <div className='task'>
                    <h2 className='task-heading'>Task 19: Energy Considerations in Procurement</h2>
                    <div className='sub-tasks-wrapper'>
                    <div className='sub-task'>
                        <div className='sub-task-label'><span className="sub-task-index">Subtask 19.1:</span>Do you evaluate your organization’s current procurement processes for items that can significantly impact energy performance?</div>
                            <RadionButtons value={subTask7} setValue={setSubTask7} valid={subTaksValidity7} setValid={setSubTaskValidity7}></RadionButtons>
                        </div>
                        <div className='sub-task'>
                            <div className='sub-task-label'><span className="sub-task-index">Subtask 19.2:</span>Do you determine and take any needed actions to adjust existing procurement processes to meet energy management system (EnMS) requirements?</div>
                            <RadionButtons value={subTask8} setValue={setSubTask8} valid={subTaksValidity8} setValid={setSubTaskValidity8}></RadionButtons>
                        </div>
                        <div className='sub-task'>
                            <div className='sub-task-label'><span className="sub-task-index">Subtask 19.3:</span>Are there any applicable specifications for purchasing energy supplies to ensure the energy performance of the equipment and services being purchased?</div>
                            <RadionButtons value={subTask9} setValue={setSubTask9} valid={subTaksValidity9} setValid={setSubTaskValidity9}></RadionButtons>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
  }