import gsap from 'gsap';
import { useGSAP } from '@gsap/react';
import { useEffect, useRef, useState } from "react";
import GeneralInfo from "../../Components/GeneralInfo/GeneralInfo";
import OrganisationInfo from "../../Components/OrganisationInfo/OrganisationInfo";
import LeaderShip from "../../Components/Leadership/Leadership";
import Support from "../../Components/Support/Support";
import Planning from "../../Components/Planning/Planning";
import Operations from "../../Components/Operations/Operations";
import PerformanceEvaluation from "../../Components/PerformanceEvaluation/PerformanceEvaluation";
import Improvement from "../../Components/Improvement/Improvement";
import Notification from "../../Components/Notification/Notification";
import "./Form.css";

export default function Form({
        setPage, 
        personPosition, companyCountry, industrialSector, companySize, numberOfSites, energyConsumption, 
        assessmentLevel, pastAudits, mandatoryAudits, energyManagementSystemBool, energyManagementSystem, 
        organizationalContextScore, leadershipScore, planningScore, supportScore, operationsScore, 
        performanceEvaluationScore, improvementScore, 
        taskScore1, taskScore2, taskScore3, taskScore4, taskScore5, taskScore6, taskScore7, taskScore8, 
        taskScore9, taskScore10, taskScore11, taskScore12, taskScore13, taskScore14, taskScore15, 
        taskScore16, taskScore17, taskScore18, taskScore19, taskScore20, taskScore21, taskScore22,
        taskScore23, taskScore24, taskScore25,
        setPersonPosition, setCompanyCountry, setIndustrialSector, setCompanySize, setNumberOfSites, 
        setEnergyConsumption, setAssessmentLevel, setPastAudits, setMandatoryAudits, 
        setEnergyManagementSystemBool, setEnergyManagementSystem, 
        setOrganizationalContextScore, setLeadershipScore, setPlanningScore, setSupportScore, 
        setOperationsScore, setPerformanceEvaluationScore, setImprovementScore, 
        setTaskScore1, setTaskScore2, setTaskScore3, setTaskScore4, setTaskScore5, setTaskScore6, 
        setTaskScore7, setTaskScore8, setTaskScore9, setTaskScore10, setTaskScore11, setTaskScore12, 
        setTaskScore13, setTaskScore14, setTaskScore15, setTaskScore16, setTaskScore17, setTaskScore18, 
        setTaskScore19, setTaskScore20, setTaskScore21, setTaskScore22, setTaskScore23, setTaskScore24, 
        setTaskScore25
    }) {

    const [step, setStep] = useState(0)
    const [showNotification, setShowNotification] = useState(false)
    const [notificationText, setNotificationText] = useState('')

    const [subTask1, setSubTask1] = useState(null)
    const [subTask2, setSubTask2] = useState(null)
    const [subTask3, setSubTask3] = useState(null)
    const [subTask4, setSubTask4] = useState(null)
    const [subTask5, setSubTask5] = useState(null)
    const [subTask6, setSubTask6] = useState(null)
    const [subTask7, setSubTask7] = useState(null)
    const [subTask8, setSubTask8] = useState(null)
    const [subTask9, setSubTask9] = useState(null)
    const [subTask10, setSubTask10] = useState(null)
    const [subTask11, setSubTask11] = useState(null)
    const [subTask12, setSubTask12] = useState(null)
    const [subTask13, setSubTask13] = useState(null)
    const [subTask14, setSubTask14] = useState(null)
    const [subTask15, setSubTask15] = useState(null)
    const [subTask16, setSubTask16] = useState(null)
    const [subTask17, setSubTask17] = useState(null)
    const [subTask18, setSubTask18] = useState(null)
    const [subTask19, setSubTask19] = useState(null)
    const [subTask20, setSubTask20] = useState(null)
    const [subTask21, setSubTask21] = useState(null)
    const [subTask22, setSubTask22] = useState(null)
    const [subTask23, setSubTask23] = useState(null)
    const [subTask24, setSubTask24] = useState(null)
    const [subTask25, setSubTask25] = useState(null)
    const [subTask26, setSubTask26] = useState(null)
    const [subTask27, setSubTask27] = useState(null)
    const [subTask28, setSubTask28] = useState(null)
    const [subTask29, setSubTask29] = useState(null)
    const [subTask30, setSubTask30] = useState(null)
    const [subTask31, setSubTask31] = useState(null)
    const [subTask32, setSubTask32] = useState(null)
    const [subTask33, setSubTask33] = useState(null)
    const [subTask34, setSubTask34] = useState(null)
    const [subTask35, setSubTask35] = useState(null)
    const [subTask36, setSubTask36] = useState(null)
    const [subTask37, setSubTask37] = useState(null)
    const [subTask38, setSubTask38] = useState(null)
    const [subTask39, setSubTask39] = useState(null)
    const [subTask40, setSubTask40] = useState(null)
    const [subTask41, setSubTask41] = useState(null)
    const [subTask42, setSubTask42] = useState(null)
    const [subTask43, setSubTask43] = useState(null)
    const [subTask44, setSubTask44] = useState(null)
    const [subTask45, setSubTask45] = useState(null)
    const [subTask46, setSubTask46] = useState(null)
    const [subTask47, setSubTask47] = useState(null)
    const [subTask48, setSubTask48] = useState(null)
    const [subTask49, setSubTask49] = useState(null)
    const [subTask50, setSubTask50] = useState(null)
    const [subTask51, setSubTask51] = useState(null)
    const [subTask52, setSubTask52] = useState(null)
    const [subTask53, setSubTask53] = useState(null)
    const [subTask54, setSubTask54] = useState(null)
    const [subTask55, setSubTask55] = useState(null)
    const [subTask56, setSubTask56] = useState(null)
    const [subTask57, setSubTask57] = useState(null)
    const [subTask58, setSubTask58] = useState(null)
    const [subTask59, setSubTask59] = useState(null)
    const [subTask60, setSubTask60] = useState(null)
    const [subTask61, setSubTask61] = useState(null)
    const [subTask62, setSubTask62] = useState(null)
    const [subTask63, setSubTask63] = useState(null)
    const [subTask64, setSubTask64] = useState(null)
    const [subTask65, setSubTask65] = useState(null)
    const [subTask66, setSubTask66] = useState(null)
    const [subTask67, setSubTask67] = useState(null)
    const [subTask68, setSubTask68] = useState(null)
    const [subTask69, setSubTask69] = useState(null)
    const [subTask70, setSubTask70] = useState(null)
    const [subTask71, setSubTask71] = useState(null)
    const [subTask72, setSubTask72] = useState(null)
    const [subTask73, setSubTask73] = useState(null)
    const [subTask74, setSubTask74] = useState(null)
    const [subTask75, setSubTask75] = useState(null)
    const [subTask76, setSubTask76] = useState(null)
    const [subTask77, setSubTask77] = useState(null)
    const [subTask78, setSubTask78] = useState(null)
    const [subTask79, setSubTask79] = useState(null)
    const [subTask80, setSubTask80] = useState(null)
    const [subTask81, setSubTask81] = useState(null)
    const [subTask82, setSubTask82] = useState(null)
    const [subTask83, setSubTask83] = useState(null)
    const [subTask84, setSubTask84] = useState(null)
    const [subTask85, setSubTask85] = useState(null)
    const [subTask86, setSubTask86] = useState(null)
    const [subTask87, setSubTask87] = useState(null)
    const [subTask88, setSubTask88] = useState(null)
    const [subTask89, setSubTask89] = useState(null)
    const [subTask90, setSubTask90] = useState(null)
    const [subTask91, setSubTask91] = useState(null)
    const [subTask92, setSubTask92] = useState(null)

    const [inputValidity, setInputValidity] = useState([
        false, false, false, false, false, false, false, false
    ])
    const [requiresInputCheck, setRequiresInputCheck] = useState([
        false, false, false, false, false, false, false, false
    ])

    const formWrapper = useRef()
    const previousButton = useRef()
    const nextButton = useRef()
    const submitButton = useRef()

    gsap.registerPlugin(useGSAP);

    const {contextSafe} = useGSAP({scope: formWrapper}); 

    const changeStep = (e) => {

        switch(e.target) {
            case previousButton.current:
                hideStep()
                showStep(false)
                break
            case nextButton.current:
                setRequiresInputCheck(prevRequiresInputCheck => prevRequiresInputCheck.map((inputCheck, index) =>
                    index === step ? true : inputCheck
                ));
                break
            case submitButton.current:
                setRequiresInputCheck(prevRequiresInputCheck => prevRequiresInputCheck.map((inputCheck, index) =>
                    index === step ? true : inputCheck
                ));
                break
            default:
                break
        }

    }
    
    const showStep = contextSafe((toggle) => {
        setTimeout(() => {
            setStep(step =>  toggle ? step + 1 : step - 1 )
            gsap.to(formWrapper.current, {opacity: 1, duration: 0.6, ease: 'Expo.easeInOut'})
        }, 600)
       
    })

    const hideStep = contextSafe(() => {

        gsap.to(formWrapper.current, {opacity: 0, duration: 0.6, ease: 'Expo.easeInOut'})      
        setTimeout(() => window.scrollTo({top: 0, behavior: "smooth"}), 300);
    })

    useEffect(() => {
        document.title = 'Maturity Model | Form';
    }, [])

    useEffect(() => {
        if(inputValidity[step]) {
            hideStep()
            setRequiresInputCheck(prevRequiresInputCheck => prevRequiresInputCheck.map((inputCheck, index) =>
                index === step ? false : inputCheck
            ));
            step !== 7 ? showStep(true) : setTimeout(() => setPage('results'), 600)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [inputValidity])

    return (
        <div className="form-wrapper" id='form-wrapper' ref={formWrapper}>
            {step === 0 &&
                <GeneralInfo
                    setShowNotification={setShowNotification}
                    setNotificationText={setNotificationText} 
                    inputValidity={inputValidity}
                    setInputValidity={setInputValidity}
                    inputCheck={requiresInputCheck}
                    personPosition={personPosition} setPersonPosition={setPersonPosition} 
                    companyCountry= {companyCountry} setCompanyCountry={setCompanyCountry}
                    industrialSector={industrialSector} setIndustrialSector={setIndustrialSector}
                    companySize={companySize} setCompanySize={setCompanySize}
                    numberOfSites={numberOfSites} setNumberOfSites={setNumberOfSites}
                    energyConsumption={energyConsumption} setEnergyConsumption={setEnergyConsumption}
                    assessmentLevel={assessmentLevel} setAssessmentLevel={setAssessmentLevel}
                    pastAudits={pastAudits} setPastAudits={setPastAudits}
                    mandatoryAudits={mandatoryAudits} setMandatoryAudits={setMandatoryAudits}
                    energyManagementSystemBool={energyManagementSystemBool} setEnergyManagementSystemBool={setEnergyManagementSystemBool}
                    energyManagementSystem={energyManagementSystem} setEnergyManagementSystem={setEnergyManagementSystem}
                ></GeneralInfo>
            }
            {step === 1  &&
                <OrganisationInfo
                    setShowNotification={setShowNotification}
                    setNotificationText={setNotificationText} 
                    inputValidity={inputValidity}
                    setInputValidity={setInputValidity}
                    inputCheck={requiresInputCheck}
                    sectionScore={organizationalContextScore} setSectionScore={setOrganizationalContextScore}
                    taskScore1={taskScore1} setTaskScore1={setTaskScore1}
                    taskScore2={taskScore2} setTaskScore2={setTaskScore2}
                    taskScore3={taskScore3} setTaskScore3={setTaskScore3}
                    subTask1={subTask1} setSubTask1={setSubTask1} 
                    subTask2={subTask2} setSubTask2={setSubTask2} 
                    subTask3={subTask3} setSubTask3={setSubTask3} 
                    subTask4={subTask4} setSubTask4={setSubTask4} 
                    subTask5={subTask5} setSubTask5={setSubTask5} 
                    subTask6={subTask6} setSubTask6={setSubTask6} 
                    subTask7={subTask7} setSubTask7={setSubTask7}
                ></OrganisationInfo>
            }
            {step === 2 &&
                <LeaderShip
                    setShowNotification={setShowNotification}
                    setNotificationText={setNotificationText}
                    inputValidity={inputValidity}
                    setInputValidity={setInputValidity}
                    inputCheck={requiresInputCheck}
                    sectionScore={leadershipScore} setSectionScore={setLeadershipScore}
                    taskScore1={taskScore4} setTaskScore1={setTaskScore4}
                    taskScore2={taskScore5} setTaskScore2={setTaskScore5}
                    taskScore3={taskScore6} setTaskScore3={setTaskScore6}
                    subTask1={subTask8} setSubTask1={setSubTask8} 
                    subTask2={subTask9} setSubTask2={setSubTask9} 
                    subTask3={subTask10} setSubTask3={setSubTask10} 
                    subTask4={subTask11} setSubTask4={setSubTask11} 
                    subTask5={subTask12} setSubTask5={setSubTask12} 
                    subTask6={subTask13} setSubTask6={setSubTask13} 
                    subTask7={subTask14} setSubTask7={setSubTask14} 
                    subTask8={subTask15} setSubTask8={setSubTask15} 
                    subTask9={subTask16} setSubTask9={setSubTask16} 
                    subTask10={subTask17} setSubTask10={setSubTask17} 
                    subTask11={subTask18} setSubTask11={setSubTask18} 
                    subTask12={subTask19} setSubTask12={setSubTask19} 
                    subTask13={subTask20} setSubTask13={setSubTask20} 
                    subTask14={subTask21} setSubTask14={setSubTask21} 
                ></LeaderShip>
            }
            {step === 3 &&
                <Planning
                    setShowNotification={setShowNotification}
                    setNotificationText={setNotificationText} 
                    inputValidity={inputValidity}
                    setInputValidity={setInputValidity}
                    inputCheck={requiresInputCheck}
                    sectionScore={planningScore} setSectionScore={setPlanningScore}
                    taskScore1={taskScore7} setTaskScore1={setTaskScore7}
                    taskScore2={taskScore8} setTaskScore2={setTaskScore8}
                    taskScore3={taskScore9} setTaskScore3={setTaskScore9}
                    taskScore4={taskScore10} setTaskScore4={setTaskScore10}
                    taskScore5={taskScore11} setTaskScore5={setTaskScore11}
                    taskScore6={taskScore12} setTaskScore6={setTaskScore12}
                    taskScore7={taskScore13} setTaskScore7={setTaskScore13}
                    subTask1={subTask22} setSubTask1={setSubTask22} 
                    subTask2={subTask23} setSubTask2={setSubTask23} 
                    subTask3={subTask24} setSubTask3={setSubTask24} 
                    subTask4={subTask25} setSubTask4={setSubTask25} 
                    subTask5={subTask26} setSubTask5={setSubTask26} 
                    subTask6={subTask27} setSubTask6={setSubTask27} 
                    subTask7={subTask28} setSubTask7={setSubTask28} 
                    subTask8={subTask29} setSubTask8={setSubTask29} 
                    subTask9={subTask30} setSubTask9={setSubTask30} 
                    subTask10={subTask31} setSubTask10={setSubTask31} 
                    subTask11={subTask32} setSubTask11={setSubTask32} 
                    subTask12={subTask33} setSubTask12={setSubTask33} 
                    subTask13={subTask34} setSubTask13={setSubTask34} 
                    subTask14={subTask35} setSubTask14={setSubTask35} 
                    subTask15={subTask36} setSubTask15={setSubTask36} 
                    subTask16={subTask37} setSubTask16={setSubTask37} 
                    subTask17={subTask38} setSubTask17={setSubTask38} 
                    subTask18={subTask39} setSubTask18={setSubTask39} 
                    subTask19={subTask40} setSubTask19={setSubTask40} 
                    subTask20={subTask41} setSubTask20={setSubTask41} 
                    subTask21={subTask42} setSubTask21={setSubTask42} 
                    subTask22={subTask43} setSubTask22={setSubTask43} 
                    subTask23={subTask44} setSubTask23={setSubTask44} 
                    subTask24={subTask45} setSubTask24={setSubTask45} 
                    subTask25={subTask46} setSubTask25={setSubTask46} 
                    subTask26={subTask47} setSubTask26={setSubTask47} 
                    subTask27={subTask48} setSubTask27={setSubTask48} 
                    subTask28={subTask49} setSubTask28={setSubTask49}
                ></Planning>
            }
            {step === 4 &&
                <Support
                    setShowNotification={setShowNotification}
                    setNotificationText={setNotificationText}
                    inputValidity={inputValidity} 
                    setInputValidity={setInputValidity}
                    inputCheck={requiresInputCheck}
                    sectionScore={supportScore} setSectionScore={setSupportScore}
                    taskScore1={taskScore14} setTaskScore1={setTaskScore14}
                    taskScore2={taskScore15} setTaskScore2={setTaskScore15}
                    taskScore3={taskScore16} setTaskScore3={setTaskScore16}
                    subTask1={subTask50} setSubTask1={setSubTask50} 
                    subTask2={subTask51} setSubTask2={setSubTask51} 
                    subTask3={subTask52} setSubTask3={setSubTask52} 
                    subTask4={subTask53} setSubTask4={setSubTask53} 
                    subTask5={subTask54} setSubTask5={setSubTask54} 
                    subTask6={subTask55} setSubTask6={setSubTask55} 
                    subTask7={subTask56} setSubTask7={setSubTask56} 
                    subTask8={subTask57} setSubTask8={setSubTask57} 
                    subTask9={subTask58} setSubTask9={setSubTask58} 
                    subTask10={subTask59} setSubTask10={setSubTask59} 
                    subTask11={subTask60} setSubTask11={setSubTask60} 
                    subTask12={subTask61} setSubTask12={setSubTask61} 
                    subTask13={subTask62} setSubTask13={setSubTask62}
                ></Support>
            }
            {step === 5 &&
                <Operations
                    setShowNotification={setShowNotification}
                    setNotificationText={setNotificationText} 
                    inputValidity={inputValidity}
                    setInputValidity={setInputValidity}
                    inputCheck={requiresInputCheck}
                    sectionScore={operationsScore} setSectionScore={setOperationsScore}
                    taskScore1={taskScore17} setTaskScore1={setTaskScore17}
                    taskScore2={taskScore18} setTaskScore2={setTaskScore18}
                    taskScore3={taskScore19} setTaskScore3={setTaskScore19}
                    subTask1={subTask63} setSubTask1={setSubTask63} 
                    subTask2={subTask64} setSubTask2={setSubTask64} 
                    subTask3={subTask65} setSubTask3={setSubTask65} 
                    subTask4={subTask66} setSubTask4={setSubTask66} 
                    subTask5={subTask67} setSubTask5={setSubTask67} 
                    subTask6={subTask68} setSubTask6={setSubTask68} 
                    subTask7={subTask69} setSubTask7={setSubTask69} 
                    subTask8={subTask70} setSubTask8={setSubTask70} 
                    subTask9={subTask71} setSubTask9={setSubTask71}
                ></Operations>
            }
            {step === 6 &&
                <PerformanceEvaluation
                    setShowNotification={setShowNotification}
                    setNotificationText={setNotificationText} 
                    inputValidity={inputValidity}
                    setInputValidity={setInputValidity}
                    inputCheck={requiresInputCheck}
                    sectionScore={performanceEvaluationScore} setSectionScore={setPerformanceEvaluationScore}
                    taskScore1={taskScore20} setTaskScore1={setTaskScore20}
                    taskScore2={taskScore21} setTaskScore2={setTaskScore21}
                    taskScore3={taskScore22} setTaskScore3={setTaskScore22}
                    taskScore4={taskScore23} setTaskScore4={setTaskScore23}
                    subTask1={subTask72} setSubTask1={setSubTask72}
                    subTask2={subTask73} setSubTask2={setSubTask73}
                    subTask3={subTask74} setSubTask3={setSubTask74}
                    subTask4={subTask75} setSubTask4={setSubTask75}
                    subTask5={subTask76} setSubTask5={setSubTask76}
                    subTask6={subTask77} setSubTask6={setSubTask77}
                    subTask7={subTask78} setSubTask7={setSubTask78}
                    subTask8={subTask79} setSubTask8={setSubTask79}
                    subTask9={subTask80} setSubTask9={setSubTask80}
                    subTask10={subTask81} setSubTask10={setSubTask81}
                    subTask11={subTask82} setSubTask11={setSubTask82}
                    subTask12={subTask83} setSubTask12={setSubTask83}
                    subTask13={subTask84} setSubTask13={setSubTask84}
                    subTask14={subTask85} setSubTask14={setSubTask85}
                    subTask15={subTask86} setSubTask15={setSubTask86}
                ></PerformanceEvaluation>
            }
             {step === 7 &&
                <Improvement
                    setShowNotification={setShowNotification}
                    setNotificationText={setNotificationText}
                    inputValidity={inputValidity} 
                    setInputValidity={setInputValidity}
                    inputCheck={requiresInputCheck}
                    sectionScore={improvementScore} setSectionScore={setImprovementScore}
                    taskScore1={taskScore24} setTaskScore1={setTaskScore24}
                    taskScore2={taskScore25} setTaskScore2={setTaskScore25}
                    subTask1={subTask87} setSubTask1={setSubTask87}
                    subTask2={subTask88} setSubTask2={setSubTask88}
                    subTask3={subTask89} setSubTask3={setSubTask89}
                    subTask4={subTask90} setSubTask4={setSubTask90}
                    subTask5={subTask91} setSubTask5={setSubTask91}
                    subTask6={subTask92} setSubTask6={setSubTask92}
                ></Improvement>
            }
            <div className='controls-wrapper'>
                <div className='step-indicator-wrapper'>
                    <p className='step-indicator-label'>Step: </p>
                    <p className='current-step'>{step + 1}</p>
                    <div className='horizontal-line'></div>
                    <p className='total-steps'>8</p>
                </div>
                <div className="buttons-wrapper">
                {step !== 0 &&
                    <button className="button outlined-button" onClick={changeStep} ref={previousButton}>Previous</button>
                }
                {step !== 7 &&
                    <button className="button filled-button" onClick={changeStep} ref={nextButton}>Next</button>
                }
                {step === 7 &&
                    <button className="button filled-button" onClick={changeStep} ref={submitButton}>Sumbit</button>
                }
            </div>
            </div>

            <Notification showNotification={showNotification} setShowNotification={setShowNotification} notificationText={notificationText}></Notification>
        </div>
    );
  }