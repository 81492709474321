import { useEffect, useState } from "react";
import Select from "../Select/Select";
import "./GeneralInfo.css"
import { containsOnlyDigits, containsSpecialCharacters } from '../../Scripts/Utilities';

export default function GeneralInfo({
    setShowNotification, setNotificationText,
    inputValidity, setInputValidity, inputCheck,
    personPosition, setPersonPosition, 
    companyCountry, setCompanyCountry, 
    industrialSector, setIndustrialSector, 
    companySize, setCompanySize, 
    numberOfSites, setNumberOfSites, 
    energyConsumption, setEnergyConsumption, 
    assessmentLevel, setAssessmentLevel, 
    pastAudits, setPastAudits, 
    mandatoryAudits, setMandatoryAudits, 
    energyManagementSystemBool, setEnergyManagementSystemBool, 
    energyManagementSystem, setEnergyManagementSystem}) {

    const [personPositionValidity, setPersonPositionValidity] = useState(true)
    const [companyCountryValidity, setCompanyCountryValidity] = useState(true)
    const [industrialSectorValidity, setIndustrialSectorValidity] = useState(true)
    const [companySizeValidity, setCompanySizeValidity] = useState(true)
    const [numberOfSitesValidity, setNumberOfSitesValidity] = useState(true)
    const [energyConsumptionValidity, setEnergyConsumptionValidity] = useState(true)
    const [assessmentLevelValidity, setAssessmentLevelValidity] = useState(true)
    const [pastAuditsValidity, setPastAuditsValidity] = useState(true)
    const [mandatoryAuditsValidity, setMandatoryAuditsValidity] = useState(true)
    const [energyManagementSystemBoolValidity, setEnergyManagementSystemBoolValidity] = useState(true)
    const [energyManagementSystemValidity, setEnergyManagementSystemValidity] = useState(true)
        
    const personPositionOptions = [
        {id: 0, text: "Management Board: Decision Maker"},
        {id: 1, text: "Mid and Lower management"},
        {id: 2, text: "Operational Staff"},
    ]

    const industrialSectorOptions = [
        {id: 0, text: "Machinery"},
        {id: 1, text: "Non metallic mineral"},
        {id: 2, text: "Chemicals"},
        {id: 3, text: "Pharmaceuticals"},
        {id: 4, text: "Metal"},
        {id: 5, text: "Plastics"},
        {id: 6, text: "Pulp & Paper"},
        {id: 7, text: "Automotive"},
        {id: 8, text: "Food"},
        {id: 9, text: "Oil & Gass Processing"},
        {id: 10, text: "Ceramic"},
        {id: 11, text: "Textile Production"},
        {id: 12, text: "Cement & Building Materials"},
        {id: 13, text: "Others"},
    ]

    const companySizeOptions = [
        {id: 0, text: "Micro (Employees <10)"},
        {id: 1, text: "Small (Employees <50)"},
        {id: 2, text: "Medium (Employees <250)"},
        {id: 3, text: "Large (Employees >250)"},
    ]

    const assessementLevelOptions = [
        {id: 0, text: 'HQ'},
        {id: 1, text: 'Site'},
        {id: 2, text: 'Entire Company'}
    ]

    const pastAuditsOptions = [
        {id: 0, text: 'Yes'},
        {id: 1, text: 'No'},
    ]

    const mandatoryAuditsOptions = [
        {id: 0, text: 'Yes'},
        {id: 1, text: 'No'},
    ]

    const energyManagementSystemOptions = [
        {id: 0, text: 'Yes'},
        {id: 1, text: 'No'},
    ]

    const checkInputValidity = () => {

        let notificationText = 'Please fill in '
        let validity = true

        if(!personPosition) {
            setPersonPositionValidity(false)
            notificationText += 'your position in the company, '
            validity = false
        }
        if(!companyCountry) {
            setCompanyCountryValidity(false)
            notificationText += 'the country the company is located in, '
            validity = false
        }
        if(!industrialSector) {
            setIndustrialSectorValidity(false)
            notificationText += "your company's industrial sector, "
            validity = false
        }
        if(!companySize) {
            setCompanySizeValidity(false)
            notificationText += "your company's size, "
            validity = false
        }
        if(!numberOfSites) {
            setNumberOfSitesValidity(false)
            notificationText += "the number of sites the company has, "
            validity = false
        }
        if(!assessmentLevel) {
            setCompanySizeValidity(false)
            notificationText += "the assessment level, "
            validity = false
        }
        if(!pastAudits) {
            setPastAuditsValidity(false)
            notificationText += "any past audits, "
            validity = false
        }
        if(!mandatoryAudits) {
            setMandatoryAuditsValidity(false)
            notificationText += "whether audits are mandatory, "
            validity = false
        }
        if(!energyManagementSystemBool) {
            setEnergyManagementSystemBoolValidity(false)
            notificationText += "whether your company use any energy management system, "
            validity = false
        }
        if(energyManagementSystemBool === '0' && !energyManagementSystem) {
            setEnergyManagementSystemValidity(false)
            notificationText += "which energy management system you use.  "
            validity = false
        }

        if(!(validity && companyCountryValidity && numberOfSitesValidity && energyConsumptionValidity)) {
            setNotificationText(notificationText.substring(0, notificationText.length - 2))
            setShowNotification(true)
            setInputValidity(inputValidity => inputValidity.map((inputValidityNew, index) =>
                index === 0 ? false : inputValidityNew
            ));
        } else {
            setInputValidity(inputValidity => inputValidity.map((inputValidityNew, index) =>
                index === 0 ? true : inputValidityNew
            ));
        }

    }

    const checkTextInputValidity = (value, setState) => {
        if(!value) {
            // setInputValidity(inputValidity => inputValidity.map((inputValidityNew, index) =>
            //     index === 0 ? false : inputValidityNew
            // ));
            setNotificationText('This is a mandatory input field')
            setShowNotification(true)
            setState(false)
        } else if(value.length < 4) {
            // setInputValidity(inputValidity => inputValidity.map((inputValidityNew, index) =>
            //     index === 0 ? false : inputValidityNew
            // ));
            setNotificationText('Text input fields should be more than 4 characters long')
            setShowNotification(true)
            setState(false)
        } else if(!containsSpecialCharacters(value)) {
            // setInputValidity(inputValidity => inputValidity.map((inputValidityNew, index) =>
            //     index === 0 ? false : inputValidityNew
            // ));
            setNotificationText('Text input fields should not contain any special character')
            setShowNotification(true)
            setState(false)
        } else {
            setState(true)
        }
    }

    const checkNumberInputValidity = (value, setState, mandatory) => {
        if(mandatory && !value) {
            setNotificationText('This is a mandatory input field')
            setShowNotification(true)
            setState(false)
        } else if(!containsOnlyDigits(value)) {
            setNotificationText('Text input fields should contain only digits (0 - 9)')
            setShowNotification(true)
            setState(false)
        } else {
            setState(true)
        }
    }

    useEffect(() => {
        if(inputCheck[0]) checkInputValidity()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [inputCheck])

    return (
        <div className="step-wrapper">
            <div className='heading-wrapper'>
                <h2 className="step-heading">General Information</h2>
            </div>
            <div className="inputs-wrapper">
                <div className="column">
                    <div className="input-wrapper">
                        <div className="label-wrapper">
                            <p className="input-label">What is your position in the company?</p>
                            <p className='mandatory-field'>*</p>
                        </div>
                        <Select options={personPositionOptions} selectedValue={personPosition} setSelectedValue={setPersonPosition} valid={personPositionValidity} setValid={setPersonPositionValidity}></Select>
                    </div>
                    <div className="input-wrapper">
                        <div className="label-wrapper">
                            <p className="input-label">In which country is your company located?</p>
                            <p className='mandatory-field'>*</p>
                        </div>
                        <input className={companyCountryValidity ? "input" : "input invalid"} type="text" placeholder="Location" value={companyCountry} onInput={e => setCompanyCountry(e.target.value)} onBlur={e => checkTextInputValidity(e.target.value, setCompanyCountryValidity)}/>
                    </div>
                    <div className="input-wrapper">
                        <div className="label-wrapper">
                            <p className="input-label">To which industrial sector does the company belong?</p>
                            <p className='mandatory-field'>*</p>
                        </div>
                        <Select options={industrialSectorOptions} selectedValue={industrialSector} setSelectedValue={setIndustrialSector} valid={industrialSectorValidity} setValid={setIndustrialSectorValidity}></Select>
                    </div>
                    <div className="input-wrapper">
                        <div className="label-wrapper">
                            <p className="input-label">What is the size of the company?</p>
                            <p className='mandatory-field'>*</p>
                        </div>
                        <Select options={companySizeOptions} selectedValue={companySize} setSelectedValue={setCompanySize} valid={companySizeValidity} setValid={setCompanySizeValidity}></Select>
                    </div>
                    <div className="input-wrapper">
                        <div className="label-wrapper">
                            <p className="input-label">How many sites does your company have?<span className="disclaimer-info">(Including Headquarters)</span></p>
                            <p className='mandatory-field'>*</p>
                        </div>
                        <input className={numberOfSitesValidity ? "input" : "input invalid"} type="text" placeholder="Number of Sites" value={numberOfSites} onInput={e => setNumberOfSites(e.target.value)} onBlur={e => checkNumberInputValidity(e.target.value, setNumberOfSitesValidity, true)}/>
                    </div>
                    <div className="input-wrapper">
                        <p className="input-label">What is the average annual energy consumption of all your company's sites? (MWh/Year)</p>
                        <input className={energyConsumptionValidity ? "input" : "input invalid"} type="number" placeholder="Number" value={energyConsumption} onInput={e => setEnergyConsumption(e.target.value)} onBlur={e => checkNumberInputValidity(e.target.value, setEnergyConsumptionValidity, false)}/>
                    </div>
                </div>
                <div className="column">
                    <div className="input-wrapper">
                        <div className="label-wrapper">
                            <p className="input-label">Energy Management Maturity Assessement Level: HQ or Site??</p>
                            <p className='mandatory-field'>*</p>
                        </div>
                        <Select options={assessementLevelOptions} selectedValue={assessmentLevel} setSelectedValue={setAssessmentLevel} valid={assessmentLevelValidity} setValid={setAssessmentLevelValidity}></Select>
                    </div>
                    <div className="input-wrapper">
                        <div className="label-wrapper">
                            <p className="input-label">Have energy audits been conducted in your company before?</p>
                            <p className='mandatory-field'>*</p>
                        </div>
                        <Select options={pastAuditsOptions} selectedValue={pastAudits} setSelectedValue={setPastAudits} valid={pastAuditsValidity} setValid={setPastAuditsValidity}></Select>
                    </div>
                    <div className="input-wrapper">
                        <div className="label-wrapper">
                            <p className="input-label">Is it mandatory to conduct energy audits in your company?</p>
                            <p className='mandatory-field'>*</p>
                        </div>
                        <Select options={mandatoryAuditsOptions} selectedValue={mandatoryAudits} setSelectedValue={setMandatoryAudits} valid={mandatoryAuditsValidity} setValid={setMandatoryAuditsValidity}></Select>
                    </div>
                    <div className="input-wrapper">
                        <div className="label-wrapper">
                            <p className="input-label">Does your company use an energy management system?</p>
                            <p className='mandatory-field'>*</p>
                        </div>
                        <Select options={energyManagementSystemOptions} selectedValue={energyManagementSystemBool} setSelectedValue={setEnergyManagementSystemBool} valid={energyManagementSystemBoolValidity} setValid={setEnergyManagementSystemBoolValidity}></Select>
                    </div>
                    <div className={energyManagementSystemBool !== '0'? "input-wrapper disabled" : "input-wrapper"}>
                        <p className="input-label">Which energy management system do you use?</p>
                        <input className={energyManagementSystemValidity ? "input" : "input invalid"} type="text" placeholder="ENM" disabled={energyManagementSystemBool !== '0' ? true: false} value={energyManagementSystem} onInput={e => setEnergyManagementSystem(e.target.value)} onBlur={e => checkTextInputValidity(e.target.value, setEnergyManagementSystemValidity)}/>
                    </div>
                </div>
            </div>
        </div>
    );
  }