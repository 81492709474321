import { useEffect, useState } from "react";
import RadionButtons from '../RadioButtons/RadioButtons';
import Scores from '../Scores/Scores';
import "./Leadership.css";

export default function LeaderShip({
    setShowNotification, setNotificationText,
    inputValidity, setInputValidity, inputCheck,
    setSectionScore, setTaskScore1, setTaskScore2, setTaskScore3,
    subTask1, setSubTask1, subTask2, setSubTask2, subTask3, setSubTask3, subTask4, setSubTask4, 
    subTask5, setSubTask5, subTask6, setSubTask6, subTask7, setSubTask7, subTask8, setSubTask8, 
    subTask9, setSubTask9, subTask10, setSubTask10, subTask11, setSubTask11, subTask12, setSubTask12, 
    subTask13, setSubTask13, subTask14, setSubTask14
   }) {

    const [subTaksValidity1, setSubTaskValidity1] = useState(true)
    const [subTaksValidity2, setSubTaskValidity2] = useState(true)
    const [subTaksValidity3, setSubTaskValidity3] = useState(true)
    const [subTaksValidity4, setSubTaskValidity4] = useState(true)
    const [subTaksValidity5, setSubTaskValidity5] = useState(true)
    const [subTaksValidity6, setSubTaskValidity6] = useState(true)
    const [subTaksValidity7, setSubTaskValidity7] = useState(true)
    const [subTaksValidity8, setSubTaskValidity8] = useState(true)
    const [subTaksValidity9, setSubTaskValidity9] = useState(true)
    const [subTaksValidity10, setSubTaskValidity10] = useState(true)
    const [subTaksValidity11, setSubTaskValidity11] = useState(true)
    const [subTaksValidity12, setSubTaskValidity12] = useState(true)
    const [subTaksValidity13, setSubTaskValidity13] = useState(true)
    const [subTaksValidity14, setSubTaskValidity14] = useState(true)

    const checkInputValidity = () => {

        let notificationText = 'Please fill in subtask(s) '
        let validity = true
      
        if(subTask1 === null) {
            setSubTaskValidity1(false)
            notificationText += '4.1, '
            validity = false
        }
        if(subTask2  === null) {
            setSubTaskValidity2(false)
            notificationText += '4.2, '
            validity = false
        }
        if(subTask3 === null) {
            setSubTaskValidity3(false)
            notificationText += '4.3, '
            validity = false
        }
        if(subTask4 === null) {
            setSubTaskValidity4(false)
            notificationText += '4.4, '
            validity = false
        }
        if(subTask5 === null) {
            setSubTaskValidity5(false)
            notificationText += '5.1, '
            validity = false
        }
        if(subTask6 === null) {
            setSubTaskValidity6(false)
            notificationText += '5.2, '
            validity = false
        }
        if(subTask7 === null) {
            setSubTaskValidity7(false)
            notificationText += '5.3, '
            validity = false
        }
        if(subTask8 === null) {
            setSubTaskValidity8(false)
            notificationText += '5.4, '
            validity = false
        }
        if(subTask9 === null) {
            setSubTaskValidity9(false)
            notificationText += '5.5, '
            validity = false
        }
        if(subTask10 === null) {
            setSubTaskValidity10(false)
            notificationText += '5.6, '
            validity = false
        }
        if(subTask11 === null) {
            setSubTaskValidity11(false)
            notificationText += '6.1, '
            validity = false
        }
        if(subTask12 === null) {
            setSubTaskValidity12(false)
            notificationText += '6.2, '
            validity = false
        }
        if(subTask13 === null) {
            setSubTaskValidity13(false)
            notificationText += '6.3, '
            validity = false
        }
        if(subTask14 === null) {
            setSubTaskValidity14(false)
            notificationText += '6.4, '
            validity = false
        }

        setInputValidity(inputValidity => inputValidity.map((inputValidityNew, index) =>
            index === 2 ? validity : inputValidityNew
        ));

        if(!validity) {
            setNotificationText(notificationText.substring(0, notificationText.length - 2))
            setShowNotification(true)
        } else {
            calculateSectionScore()
            calculateTasksScore()
        }

    }

    const calculateSectionScore = () => {
        setSectionScore(Math.round((subTask1 + subTask2 + subTask3 + subTask4 + subTask5 + subTask6 + subTask7 + subTask8 + subTask9 + subTask10 + subTask11 + subTask12 + subTask13 + subTask14) / 14 * 20))
    }

    const calculateTasksScore = () => {
        setTaskScore1(Math.round((subTask1 + subTask2 + subTask3 + subTask4) / 4 * 20))
        setTaskScore2(Math.round((subTask5 + subTask6 + subTask7 + subTask8 + subTask9 + subTask10) / 6 * 20))
        setTaskScore3(Math.round((subTask11 + subTask12 + subTask13 + subTask14) / 4 * 20))
    }

    useEffect(() => {
        if(inputCheck[2]) checkInputValidity()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [inputCheck])

    return (
        <div className="step-wrapper">
            <div className='heading-wrapper'>
                <h2 className="step-heading">Leadership</h2>
                <Scores></Scores>
            </div>
            <div className="inputs-wrapper">
                <div className='task'>
                    <h2 className='task-heading'>Task 4: Top Management and Leadership Commitment</h2>
                    <div className='sub-tasks-wrapper'>
                        <div className='sub-task'>
                            <div className='sub-task-label'><span className="sub-task-index">Subtask 4.1:</span>Do you have the support of upper management for the ongoing advancement of energy performance and the implementation of an EnMS system?</div>
                            <RadionButtons value={subTask1} setValue={setSubTask1} valid={subTaksValidity1} setValid={setSubTaskValidity1}></RadionButtons>
                        </div>
                        <div className='sub-task'>
                            <div className='sub-task-label'><span className="sub-task-index">Subtask 4.2:</span>Do you brief top management on their energy management system (EnMS) leadership responsibilities?</div>
                            <RadionButtons value={subTask2} setValue={setSubTask2} valid={subTaksValidity2} setValid={setSubTaskValidity2}></RadionButtons>
                        </div>
                        <div className='sub-task'>
                            <div className='sub-task-label'><span className="sub-task-index">Subtask 4.3:</span>Has the energy policy, objectives, and energy targets been aligned with the strategic direction of the organisation?</div>
                            <RadionButtons value={subTask3} setValue={setSubTask3} valid={subTaksValidity3} setValid={setSubTaskValidity3}></RadionButtons>
                        </div>
                        <div className='sub-task'>
                            <div className='sub-task-label'><span className="sub-task-index">Subtask 4.4:</span>Do you ensure that the energy performance is effectively represented by appropriate Energy Performance Indicators (EnPIs)?</div>
                            <RadionButtons value={subTask4} setValue={setSubTask4} valid={subTaksValidity4} setValid={setSubTaskValidity4}></RadionButtons>
                        </div>
                    </div>
                </div>
            </div>
            <div className="inputs-wrapper">
                <div className='task'>
                    <h2 className='task-heading'>Task 5: Energy Policy</h2>
                    <div className='sub-tasks-wrapper'>
                        <div className='sub-task'>
                            <div className='sub-task-label'><span className="sub-task-index">Subtask 5.1:</span>Do you draft your energy policy statement?</div>
                            <RadionButtons value={subTask5} setValue={setSubTask5} valid={subTaksValidity5} setValid={setSubTaskValidity5}></RadionButtons>
                        </div>
                        <div className='sub-task'>
                            <div className='sub-task-label'><span className="sub-task-index">Subtask 5.2:</span>Does top management approves the energy policy?</div>
                            <RadionButtons value={subTask6} setValue={setSubTask6} valid={subTaksValidity6} setValid={setSubTaskValidity6}></RadionButtons>
                        </div>
                        <div className='sub-task'>
                            <div className='sub-task-label'><span className="sub-task-index">Subtask 5.3:</span>Does the energy policy provide a framework for setting and reviewing objectives and energy targets?</div>
                            <RadionButtons value={subTask7} setValue={setSubTask7} valid={subTaksValidity7} setValid={setSubTaskValidity7}></RadionButtons>
                        </div>
                        <div className='sub-task'>
                            <div className='sub-task-label'><span className="sub-task-index">Subtask 5.4:</span>Does the energy policy include a commitment to continually improve the energy performance of the EnMS?</div>
                            <RadionButtons value={subTask8} setValue={setSubTask8} valid={subTaksValidity8} setValid={setSubTaskValidity8}></RadionButtons>
                        </div>
                        <div className='sub-task'>
                            <div className='sub-task-label'><span className="sub-task-index">Subtask 5.5:</span>Has the organization's energy policy been communicated and made available to employees and interested parties in the form of documented information?</div>
                            <RadionButtons value={subTask9} setValue={setSubTask9} valid={subTaksValidity9} setValid={setSubTaskValidity9}></RadionButtons>
                        </div>
                        <div className='sub-task'>
                            <div className='sub-task-label'><span className="sub-task-index">Subtask 5.6:</span>Do you regularly review and update your energy policy?</div>
                            <RadionButtons value={subTask10} setValue={setSubTask10} valid={subTaksValidity10} setValid={setSubTaskValidity10}></RadionButtons>
                        </div>
                    </div>
                </div>
            </div>
            <div className="inputs-wrapper">
                <div className='task'>
                    <h2 className='task-heading'>Task 6:  Energy Management Team and Resources</h2>
                    <div className='sub-tasks-wrapper'>
                        <div className='sub-task'>
                            <div className='sub-task-label'><span className="sub-task-index">Subtask 6.1:</span>Do you establish an energy management team and obtain authorization from upper management for it to supervise the energy management system (EnMS) and carry out assigned responsibilities?</div>
                            <RadionButtons value={subTask11} setValue={setSubTask11} valid={subTaksValidity11} setValid={setSubTaskValidity11}></RadionButtons>
                        </div>
                        <div className='sub-task'>
                            <div className='sub-task-label'><span className="sub-task-index">Subtask 6.2:</span>Do you assign and communicate EnMS responsibilities and authorities within the organization?</div>
                            <RadionButtons value={subTask12} setValue={setSubTask12} valid={subTaksValidity12} setValid={setSubTaskValidity12}></RadionButtons>
                        </div>
                        <div className='sub-task'>
                            <div className='sub-task-label'><span className="sub-task-index">Subtask 6.3:</span>Does the energy management team provide management with periodic reports on the performance of the EnMS and the improvement of energy performance?</div>
                            <RadionButtons value={subTask13} setValue={setSubTask13} valid={subTaksValidity13} setValid={setSubTaskValidity13}></RadionButtons>
                        </div>
                        <div className='sub-task'>
                            <div className='sub-task-label'><span className="sub-task-index">Subtask 6.4:</span>Does the energy management team have the authority to estabish criteria, methodologies and processes ensuring effective operation, control and continual improvement of the EnMS?</div>
                            <RadionButtons value={subTask14} setValue={setSubTask14} valid={subTaksValidity14} setValid={setSubTaskValidity14}></RadionButtons>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
  }