import gsap from 'gsap';
import { useState, useRef, useEffect } from "react";
import { useGSAP } from '@gsap/react';
import arrowDownIcon from '../../Assets/Images/arrow-down.png'
import "./Select.css"

export default function Select({options, selectedValue, setSelectedValue, valid, setValid}) {

    const [isOpen, setIsOpen] = useState(false)
    const [optionSelected, setOptionSelected] = useState(null)

    const selectContainer = useRef();
    const optionsList = useRef();

    gsap.registerPlugin(useGSAP);

    const {contextSafe} = useGSAP({scope: selectContainer}); 

    const toggleOptions = () => {
        !isOpen ? showOptions() : hideOptions()
        setIsOpen(!isOpen)
    }

    const showOptions = contextSafe(() => {
        gsap.set(optionsList.current, {scaleY: 0})
        gsap.to(optionsList.current, {scaleY: 1, display: 'block', duration: 0.4, ease: 'Expo.easeInOut'});
    })

    const hideOptions = contextSafe(() => {
        gsap.to(optionsList.current, {scaleY: 0, display: 'none', duration: 0.4, ease: 'Expo.easeInOut'});
    })

    const chooseOption = (e) => {
        setOptionSelected(e.target)
        setSelectedValue(e.target.getAttribute("option-value"))
        hideOptions()
        setValid(true)
        setIsOpen(false)
    }

    useEffect(() => {

        const clickOutside = (e) => {
            if(!selectContainer.current.contains(e.target)) {
                hideOptions()
                setIsOpen(false)
            }
        }

        window.addEventListener('click', clickOutside)

        return () => {
            window.removeEventListener('click', clickOutside);
        }
        
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div className="select-wrapper" ref={selectContainer}>
            <div className={valid ? "select-header" : "select-header invalid"} onClick={toggleOptions}>
                <div className={selectedValue ? "select-header-text" : "select-header-text faded"} >{selectedValue ? options[parseInt(selectedValue)].text : 'Select'}</div>
                <img className="select-header-icon" src={arrowDownIcon} alt="arrow down"/>
            </div>
            <ul className="options" ref={optionsList}>
                {options.map(option => (
                    <li 
                        className={optionSelected && parseInt(optionSelected.getAttribute('option-value')) === option.id ? "option selected" : "option"} 
                        option-value={option.id} 
                        key={option.id}
                        onClick={chooseOption}
                    >{option.text}</li>
                ))}
            </ul>
        </div>
    );
  }