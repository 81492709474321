import { useEffect, useState } from "react";
import RadionButtons from '../RadioButtons/RadioButtons';
import Scores from '../Scores/Scores';
import "./PerformanceEvaluation.css";

export default function PerformanceEvaluation({
    setShowNotification, setNotificationText,
    inputValidity, setInputValidity, inputCheck,
    setSectionScore,
    setTaskScore1, setTaskScore2, setTaskScore3, setTaskScore4,
    subTask1, setSubTask1, subTask2, setSubTask2, subTask3, setSubTask3, subTask4, setSubTask4, 
    subTask5, setSubTask5, subTask6, setSubTask6, subTask7, setSubTask7, subTask8, setSubTask8, 
    subTask9, setSubTask9, subTask10, setSubTask10, subTask11, setSubTask11, subTask12, setSubTask12, 
    subTask13, setSubTask13, subTask14, setSubTask14, subTask15, setSubTask15,
   }) {

    const [subTaksValidity1, setSubTaskValidity1] = useState(true)
    const [subTaksValidity2, setSubTaskValidity2] = useState(true)
    const [subTaksValidity3, setSubTaskValidity3] = useState(true)
    const [subTaksValidity4, setSubTaskValidity4] = useState(true)
    const [subTaksValidity5, setSubTaskValidity5] = useState(true)
    const [subTaksValidity6, setSubTaskValidity6] = useState(true)
    const [subTaksValidity7, setSubTaskValidity7] = useState(true)
    const [subTaksValidity8, setSubTaskValidity8] = useState(true)
    const [subTaksValidity9, setSubTaskValidity9] = useState(true)
    const [subTaksValidity10, setSubTaskValidity10] = useState(true)
    const [subTaksValidity11, setSubTaskValidity11] = useState(true)
    const [subTaksValidity12, setSubTaskValidity12] = useState(true)
    const [subTaksValidity13, setSubTaskValidity13] = useState(true)
    const [subTaksValidity14, setSubTaskValidity14] = useState(true)
    const [subTaksValidity15, setSubTaskValidity15] = useState(true)

    const checkInputValidity = () => {
        
        let notificationText = 'Please fill in subtask(s) '
        let validity = true

        if(subTask1 === null) {
            setSubTaskValidity1(false)
            notificationText += '20.1, '
            validity = false
        }
        if(subTask2  === null) {
            setSubTaskValidity2(false)
            notificationText += '20.2, '
            validity = false
        }
        if(subTask3 === null) {
            setSubTaskValidity3(false)
            notificationText += '20.3, '
            validity = false
        }
        if(subTask4 === null) {
            setSubTaskValidity4(false)
            notificationText += '21.1, '
            validity = false
        }
        if(subTask5 === null) {
            setSubTaskValidity5(false)
            notificationText += '21.2, '
            validity = false
        }
        if(subTask6 === null) {
            setSubTaskValidity6(false)
            notificationText += '21.3, '
            validity = false
        }
        if(subTask7 === null) {
            setSubTaskValidity7(false)
            notificationText += '22.1, '
            validity = false
        }
        if(subTask8 === null) {
            setSubTaskValidity8(false)
            notificationText += '22.2, '
            validity = false
        }
        if(subTask9  === null) {
            setSubTaskValidity9(false)
            notificationText += '22.3, '
            validity = false
        }
        if(subTask10 === null) {
            setSubTaskValidity10(false)
            notificationText += '22.4, '
            validity = false
        }
        if(subTask11 === null) {
            setSubTaskValidity11(false)
            notificationText += '23.1, '
            validity = false
        }
        if(subTask12 === null) {
            setSubTaskValidity12(false)
            notificationText += '23.2, '
            validity = false
        }
        if(subTask13 === null) {
            setSubTaskValidity13(false)
            notificationText += '23.3, '
            validity = false
        }
        if(subTask14 === null) {
            setSubTaskValidity14(false)
            notificationText += '23.4, '
            validity = false
        }
        if(subTask15 === null) {
            setSubTaskValidity15(false)
            notificationText += '23.5, '
            validity = false
        }

        setInputValidity(inputValidity => inputValidity.map((inputValidityNew, index) =>
            index === 6 ? validity : inputValidityNew
        ));

        if(!validity) {
            setNotificationText(notificationText.substring(0, notificationText.length - 2))
            setShowNotification(true)
        } else {
            calculateSectionScore()
            calculateTasksScore()
        }

    }

    const calculateSectionScore = () => {
        setSectionScore(Math.round((subTask1 + subTask2 + subTask3 + subTask4 + subTask5 + subTask6 + subTask7 + subTask8 + subTask9 + subTask10 + subTask11 + subTask12 + subTask13 + subTask14 + subTask15) / 15 * 20))
    }

    const calculateTasksScore = () => {
        setTaskScore1(Math.round((subTask1 + subTask2 + subTask3) / 3 * 20))
        setTaskScore2(Math.round((subTask4 + subTask5 + subTask6) / 3 * 20))
        setTaskScore3(Math.round((subTask7 + subTask8 + subTask9 + subTask10) / 4 * 20))
        setTaskScore4(Math.round((subTask11 + subTask12 + subTask13 + subTask14 + subTask15) / 5 * 20))
    }

    useEffect(() => {
        if(inputCheck[6]) checkInputValidity()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [inputCheck])

    return (
        <div className="step-wrapper">
            <div className='heading-wrapper'>
                <h2 className="step-heading">Performance Evaluation</h2>
                <Scores></Scores>
            </div>
            <div className="inputs-wrapper">
                <div className='task'>
                    <h2 className='task-heading'>Task 20: Monitoring and Measurement of the EnMS</h2>
                    <div className='sub-tasks-wrapper'>
                        <div className='sub-task'>
                            <div className='sub-task-label'><span className="sub-task-index">Subtask 20.1:</span>Do you determine what data or information is required to track, measure, analyse, and evaluate the outcomes of the EnMS and its effectiveness in relation to the EnMS's planned outcomes and the organizational strategy's goals and priorities?</div>
                            <RadionButtons value={subTask1} setValue={setSubTask1} valid={subTaksValidity1} setValid={setSubTaskValidity1}></RadionButtons>
                        </div>
                        <div className='sub-task'>
                            <div className='sub-task-label'><span className="sub-task-index">Subtask 20.2:</span>Do you determine the methods to be used, the timing of monitoring and measurement, and the analysis and evaluation of the results?</div>
                            <RadionButtons value={subTask2} setValue={setSubTask2} valid={subTaksValidity2} setValid={setSubTaskValidity2}></RadionButtons>
                        </div>
                        <div className='sub-task'>
                            <div className='sub-task-label'><span className="sub-task-index">Subtask 20.3:</span>Do you conduct monitoring and measurement analysis to assess the performance of your Energy Management System (EnMS) and evaluate its effectiveness?</div>
                            <RadionButtons value={subTask3} setValue={setSubTask3} valid={subTaksValidity3} setValid={setSubTaskValidity3}></RadionButtons>
                        </div>
                    </div>
                </div>
            </div>
            <div className="inputs-wrapper">
                <div className='task'>
                    <h2 className='task-heading'>Task 21: Monitoring and Measurement of Energy Performance Improvement</h2>
                    <div className='sub-tasks-wrapper'>
                        <div className='sub-task'>
                            <div className='sub-task-label'><span className="sub-task-index">Subtask 21.1:</span>Do you determine what needs to be monitored and measured for energy performance, including the key characteristics of operations affecting energy performance?</div>
                            <RadionButtons value={subTask4} setValue={setSubTask4} valid={subTaksValidity4} setValid={setSubTaskValidity4}></RadionButtons>
                        </div>
                        <div className='sub-task'>
                            <div className='sub-task-label'><span className="sub-task-index">Subtask 21.2:</span>Do you evaluate your organization’s energy performance by comparing EnPI values to the corresponding EnB?</div>
                            <RadionButtons value={subTask5} setValue={setSubTask5} valid={subTaksValidity5} setValid={setSubTaskValidity5}></RadionButtons>
                        </div>
                        <div className='sub-task'>
                            <div className='sub-task-label'><span className="sub-task-index">Subtask 21.3:</span>Do you establish a process for investigating and responding to  deviations of EnPI values from EnB values and for retaining records of the results?</div>
                            <RadionButtons value={subTask6} setValue={setSubTask6} valid={subTaksValidity6} setValid={setSubTaskValidity6}></RadionButtons>
                        </div>
                    </div>
                </div>
            </div>
            <div className="inputs-wrapper">
                <div className='task'>
                    <h2 className='task-heading'>Task 22: Internal Audit</h2>
                    <div className='sub-tasks-wrapper'>
                        <div className='sub-task'>
                            <div className='sub-task-label'><span className="sub-task-index">Subtask 22.1:</span>Do you conduct regularly scheduled EnMS internal audits to identify areas of success and areas in need of improvement?</div>
                            <RadionButtons value={subTask7} setValue={setSubTask7} valid={subTaksValidity7} setValid={setSubTaskValidity7}></RadionButtons>
                        </div>
                        <div className='sub-task'>
                            <div className='sub-task-label'><span className="sub-task-index">Subtask 22.2:</span>Do you develop a documented internal audit procedure that addresses the responsibilities, planning, and conducting of EnMS internal audits, as well as the reporting of audit results?</div>
                            <RadionButtons value={subTask8} setValue={setSubTask8} valid={subTaksValidity8} setValid={setSubTaskValidity8}></RadionButtons>
                        </div>
                        <div className='sub-task'>
                            <div className='sub-task-label'><span className="sub-task-index">Subtask 22.3:</span>Do you identify personnel to serve as EnMS internal auditors?</div>
                            <RadionButtons value={subTask9} setValue={setSubTask9} valid={subTaksValidity9} setValid={setSubTaskValidity9}></RadionButtons>
                        </div>
                        <div className='sub-task'>
                            <div className='sub-task-label'><span className="sub-task-index">Subtask 22.4:</span>Do you record the results of your organization’s internal audits and ensure that the internal audit results are reported to relevant management?</div>
                            <RadionButtons value={subTask10} setValue={setSubTask10} valid={subTaksValidity10} setValid={setSubTaskValidity10}></RadionButtons>
                        </div>
                    </div>
                </div>
            </div>
            <div className="inputs-wrapper">
                <div className='task'>
                    <h2 className='task-heading'>Task 23: Management Review</h2>
                    <div className='sub-tasks-wrapper'>
                        <div className='sub-task'>
                            <div className='sub-task-label'><span className="sub-task-index">Subtask 23.1:</span>Do you define the intervals at which top management will review the energy management system (EnMS) and energy performance?</div>
                            <RadionButtons value={subTask11} setValue={setSubTask11} valid={subTaksValidity11} setValid={setSubTaskValidity11}></RadionButtons>
                        </div>
                        <div className='sub-task'>
                            <div className='sub-task-label'><span className="sub-task-index">Subtask 23.2:</span>Do you take into consideration when conducting the management review the extent to which the energy targets have been achieved?</div>
                            <RadionButtons value={subTask12} setValue={setSubTask12} valid={subTaksValidity12} setValid={setSubTaskValidity12}></RadionButtons>
                        </div>
                        <div className='sub-task'>
                            <div className='sub-task-label'><span className="sub-task-index">Subtask 23.3:</span>Do you take into consideration when conducting the management review the energy performance and its improvement based on monitoring and measurement results including EnPIs?</div>
                            <RadionButtons value={subTask13} setValue={setSubTask13} valid={subTaksValidity13} setValid={setSubTaskValidity13}></RadionButtons>
                        </div>
                        <div className='sub-task'>
                            <div className='sub-task-label'><span className="sub-task-index">Subtask 23.4:</span>Do you implement the outputs of management reviews?</div>
                            <RadionButtons value={subTask14} setValue={setSubTask14} valid={subTaksValidity14} setValid={setSubTaskValidity14}></RadionButtons>
                        </div>
                        <div className='sub-task'>
                            <div className='sub-task-label'><span className="sub-task-index">Subtask 23.5:</span>Do you determine if the EnMS continues to be suitable, adequate, effective, and aligned with your organization’s energy policy and strategic direction?</div>
                            <RadionButtons value={subTask15} setValue={setSubTask15} valid={subTaksValidity15} setValid={setSubTaskValidity15}></RadionButtons>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
  }