import lifeLogo from '../../Assets/Images/life-logo.jpg'
import './Footer.css'

export default function Footer() {
    return (
        <div className="footer">
            <div className="disclaimer">
                <img className="disclaimer-img" src={lifeLogo} alt="Life Logo"/>
                <p className="disclaimer-text">AUDIT2MEASURE is co-funded by the European Union under GA 101075785.Views and opinions expressed are however those of the author(s) only and do not necessarily reflect those of the European Union or CINEA. Neither the European Union nor the granting authority can be held responsible for them.</p>
            </div>
            <p className="nd-info">"Non-disclosure of information: We take the protection of your personal data very seriously. To ensure the security of your personal data, your data will be conscientiously protected from unauthorized access or disclosure and will not be disclosed to third parties without your authorization. The collected data will only be used for project-related analysis and will only be made available to the project's consortium partners. The information obtained will not be used outside of the Audit2Measure project. Any information that you designate as confidential will be treated as confidential by us. We (the project partners) may keep a copy to the extent it is required to keep, archive or store such Confidential Information because of compliance with applicable laws and regulations or for the proof of on-going obligations provided that we comply with the confidentiality obligations herein contained with respect to such copy.   You have the right to receive a copy of your personal data stored by us and if you so desire, you can have you personal data destroyed under the GDPR regulation and subsequent ‘right to be forgotten’."</p>
        </div>
    );
  }