import gsap from 'gsap';
import { useEffect, useRef, useState } from "react";
import "./RadioButton.css"

export default function RadionButton({radioValue, isChecked, setIsChecked, setValue, title, checkedColor}) {

    const [showTitle, setShowTitle] = useState(false)

    const radioCircle = useRef()
    const radioTitle = useRef()
    const radioLabel = useRef()

    let titleTimeOut = null

    const toggleRadioButton = () => {
        setIsChecked(prevIsChecked => prevIsChecked.map((isCheckedNew, index) =>
            index === parseInt(radioValue) ? !isChecked : false
        ));
        setValue(radioValue)
    }

    const displayTitle = () => {
        if(!isChecked) {
            gsap.set(radioCircle.current, {borderColor: checkedColor})
            gsap.set(radioLabel.current, {color: checkedColor})
        }
        titleTimeOut = setTimeout(() => {setShowTitle(true)}, 800)
    }
    const hideTitle = (e) => {
        clearTimeout(titleTimeOut)
        showTitle ? setShowTitle(false) : setTimeout(() => {setShowTitle(false)}, 600)
        if(!isChecked) {
            gsap.set(radioCircle.current, {borderColor: '#1A202C'})
            gsap.set(radioLabel.current, {color: '#1A202C'})
        }
    }

    useEffect(() => {
        if(showTitle && !isChecked) {
            gsap.set(radioTitle.current, {backgroundColor: checkedColor})
            if(radioValue === 4) gsap.set(radioTitle.current, {x: '-85%'})
            if(radioValue === 0) gsap.set(radioTitle.current, {x: '0%'})
            if(radioValue === 3 && window.innerWidth < 480) gsap.set(radioTitle.current, {x: '-65%'})
            if(radioValue === 1 && window.innerWidth < 480) gsap.set(radioTitle.current, {x: '-25%'})
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showTitle])

    useEffect(() => {
        gsap.set(radioCircle.current, {
            backgroundColor: isChecked ? checkedColor : 'transparent',
            borderColor: isChecked ? checkedColor : '#1A202C'
        })
        gsap.set(radioLabel.current, {
            color: isChecked ? checkedColor : '#1A202C'
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isChecked])

    return (
        <div className="radio-button-wrapper" onClick={toggleRadioButton} onMouseEnter={displayTitle} onMouseLeave={hideTitle}>
            <div className={isChecked ? "radio-button-checker checked" : "radio-button-checker"} ref={radioCircle}></div>
            <p className="radio-button-value" ref={radioLabel}>{radioValue}</p>
            { showTitle &&
                <p className="radio-button-title" ref={radioTitle}>{title}</p>
            }
        </div>
    );
  }