import gsap from 'gsap';
import { useRef, useEffect } from "react";
import { useGSAP } from '@gsap/react';
import warningIcon from '../../Assets/Images/warning.png'
import "./Notification.css"

export default function Notification({showNotification, setShowNotification, notificationText}) {

    const notificationContainer = useRef();

    gsap.registerPlugin(useGSAP);

    const {contextSafe} = useGSAP({scope: notificationContainer}); 

    const toggleNotification = () => {
        if(showNotification) displayNotification()
    }

    const displayNotification = contextSafe(() => {
        gsap.set(notificationContainer.current, {x: 100, opacity: 0})
        gsap.to(notificationContainer.current, {
            x: 0, 
            opacity: 1, 
            duration: 0.8, 
            ease: 'Expo.easeInOut', 
            onComplete: () => {setTimeout(() => hideNotification(), 8000)}
        });
    })

    const hideNotification = contextSafe(() => {
        gsap.to(notificationContainer.current, {
            x: 100, 
            opacity: 0, 
            duration: 0.8, 
            ease: 'Expo.easeInOut',
            onComplete: () => setShowNotification(false)
        });
    })

    useEffect(() => {
       toggleNotification()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showNotification])

    return (
        <>
        {showNotification && 
            <div className="notification" ref={notificationContainer} onClick={hideNotification}>
                <img className="notification-image" src={warningIcon} alt="warning"/>
                <p className="notification-text">{notificationText}</p>
            </div>
        }
        </>
    );
  }