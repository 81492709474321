import { useEffect, useState } from "react";
import RadionButton from "../RadioButton/RadionButton";
import "./RadioButtons.css";

export default function RadionButtons({value, setValue}) {

    const [isChecked, setIsChecked] = useState([
        parseInt(value) === 0 ? true : false,
        parseInt(value) === 1 ? true : false,
        parseInt(value) === 2 ? true : false,
        parseInt(value) === 3 ? true : false,
        parseInt(value) === 4 ? true : false
    ]);

    const titles = [
        'We are uncertain what this sub-task mean',
        'We understand what is required for this sub-task but have not yet started acting on it',
        'We are taking some action on this sub task but not yet to the extent of the description provided in the above two choices',
        'Our operations for this sub-task are currently functional, but we believe that they could benefit from further improvement in the future',
        'We have taken systematic and well-planned actions in this subtask, and these actions have been tested over time'
    ]

    const colorScale = ['#fe9f9f', '#e39ec9', '#bfa4d7', '#9ea8cc', '#89a8b5']

    useEffect(() => {
        setIsChecked(prevIsChecked => prevIsChecked.map((newIsChecked, index) =>
            index === parseInt(value) ? true : false
        ))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
     <div className="radio-buttons">
        {isChecked.map(((isCheckedItem, index) => (
            <RadionButton 
                key={index} 
                radioValue={index} 
                isChecked={isCheckedItem} 
                setIsChecked={setIsChecked} 
                setValue={setValue}
                title={titles[index]}
                checkedColor={colorScale[index]}
            ></RadionButton>
        )))}
     </div>
    );
  }